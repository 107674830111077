import React, { useContext, useEffect, useReducer, useState } from "react";
import axios from "axios";
import news_reducer from "../reducers/news_reducer";

const defaultState = {
  news_loading: false,
  news_error: false,
  news: [],
  filtered_news: [],
  pagination:[],
  sidebarBlogs:[],
  categoryBlogs:[]
};

const NewsContext = React.createContext();

export const NewsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(news_reducer, defaultState);
  const baseUrl = "https://certificatesglobal.com/backend/public/api";
  const [page, setPage] = useState(1);

  const fetchNews = async () => {
    dispatch({ type: "GET_NEWS_BEGIN" });
    try {
      const { data } = await axios.get(`${baseUrl}/blogs?page=${page}`);
      if (!data.success) {
        throw new Error("Failed to fetch data");
      }
      dispatch({ type: "GET_NEWS_SUCCESS", payload: {data:data.data.collection, pagination:data.data.pagination} });
    } catch (error) {
      dispatch({ type: "GET_NEWS_ERROR" });
      console.log("error in API ",error)
    }
  };

  const getRightSidebarNews =  async () =>{
    try {
      const {data} = await axios.get(`${baseUrl}/blogs/tags/right-slider`);
      if (!data.success) {
        throw new Error("Failed to fetch data");
      }
       dispatch({type:"GET_RIGHT_SIDEBAR_NEWS", payload:data.data.blogs})
    } catch (error) {
      console.log("Error in right sidebar API",error)
    }

  }

  const getNewsbyCategory = async() =>{
    try {
      const {data} = await axios.get(`${baseUrl}/blogs/categories`);
      if (!data.success) {
        throw new Error("Failed to fetch data");
      }
       dispatch({type:"GET_CATEGORY_NEWS", payload:data.data.blogs})
    } catch (error) {
      console.log("Error in category blogs API",error)
    }
  }
  useEffect(() => {
    fetchNews();
    getRightSidebarNews()
  }, [page]);

  useEffect(() =>{
    getNewsbyCategory()
  },[]);

  return (
    <NewsContext.Provider value={{ ...state, fetchNews,page,setPage }}>
      {children}
    </NewsContext.Provider>
  );
};

export const useNewsContext = () => {
  return useContext(NewsContext);
};
