import React from 'react';
import SEO from '../../common/SEO';
import Header from '../../common/header/Header';
import Breadcrumb from '../../common/breadcrumb/Breadcrumb';
import Footer from '../../common/footer/Footer';
import AboutOne from '../../components/about/AboutOne';
import AboutUsService from './AboutUsService';
import WhyChooseAbout from '../../components/about/WhyChooseAbout';
import AboutUsExpert from './AboutUsExpert';
import TestimonialSectionTwo from '../../components/testimonial-section/TestimonialSectionTwo';

const AboutUs = () => {
    return (
        <>
            <SEO title="About Us" preload="/images/about/about-main.webp"/>

            <Header styles="header-style-2" />

            <Breadcrumb 
                title="About Us"
                rootUrl="/"
                parentUrl="Home"
                currentUrl="About Us"
            />

             <AboutOne />
            
             <AboutUsService />

             <WhyChooseAbout />
           
             <AboutUsExpert />
           
            <TestimonialSectionTwo />

            <Footer />
        </>
    )
}

export default AboutUs;