import React from 'react';
import { Link } from 'react-router-dom';
import { slugify } from '../../utils';

const PostTwo = ( { data, classes, bgWhite } ) => {
    return (
        <div className={`edu-blog blog-type-3 radius-small ${classes ? classes : '' } ${bgWhite === 'enable' ? 'bg-color-white' : '' }`}>
            <div className="inner">
                <div className="content">
                    <div className="status-group">
                        Education
                        {/* <Link className="eduvibe-status status-05 color-primary w-800" to={process.env.PUBLIC_URL + `/category/${ slugify( data.categories.slice( 0, 1 ) ) }`}>{data.categories.slice(0, 1)}</Link> */}
                    </div>
                    <h3 className="title fs-1">
                        <Link to={process.env.PUBLIC_URL + `/blog-details-right-sidebar/${data.id}`}>{data.title.substring(0,55)+"..."}</Link>
                    </h3>
                    <div className="blog-card-bottom">
                        <ul className="blog-meta">
                            <li><i className="icon-calendar-2-line"></i>{ data.updated_at.substring(0,10) }</li>
                            <li><i className="icon-user-line"></i>Posted By Seeding Brains</li>
                        </ul>
                    </div>
                </div>
                <div className="thumbnail">
                    <Link to={process.env.PUBLIC_URL + `/blog-details-right-sidebar/${data.id}`} aria-label={data.image_alt}>
                        <img src={data.featured_image} alt="Blog Thumb"  width="786" height="573" alt={(data.image_alt)? data.image_alt : "Sed News Image"} loading='lazy'/>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default PostTwo;