import React from 'react';
import { Link } from 'react-router-dom';
import { useNewsContext } from '../../context/news_context';

const LatestPost = ({style2,extraClass} ) => {
    const {sidebarBlogs} = useNewsContext()
    return (
        <div className={`edu-blog-widget${ style2 === 'enable' ? '-2' : '' } widget-latest-post ${ extraClass || '' }`}>
            <div className="inner">
                <h5 className="widget-title">Latest Post</h5>
                <div className="content latest-post-list">
                    {
                        sidebarBlogs.map( ( item ) => {
                            return(
                                <div className="latest-post" key={ item.id }>
                                    <div className="thumbnail" >
                                        <Link className="d-block" to={process.env.PUBLIC_URL + `/blog-details-right-sidebar/${item.id}`} style={{height:"100px", width:"100px"}}>
                                            <img src={item.featured_image} alt="Blog Thumb"  />
                                        </Link>
                                    </div>
                                    <div className="post-content">
                                        {/* <ul className="blog-meta">
                                            <li>{item.date}</li>
                                        </ul> */}
                                        <h6 className="title">
                                            <Link className="d-block" to={process.env.PUBLIC_URL + `/blog-details-right-sidebar/${item.id}`}>{item.title}</Link>
                                        </h6>
                                    </div>
                                </div>
                            )
                        } )
                    }
                </div>
            </div>
        </div>
    )
}

export default LatestPost;