import React, { useEffect, useState } from 'react';
import ComingSoon from '../../pages/innerpages/ComingSoon';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import EventOne from '../event/EventOne';
import Layout from '../../common/Layout';

const HomeTwoEvents = () => {
    const baseUrl = 'https://certificatesglobal.com/backend/public/api'
    const [categories, setCategories] = useState([])
    const { category: categorySlug } = useParams()

  useEffect(() => {
    getCategories()
  }, [categorySlug])

    const getCategories = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/product-categories/${categorySlug}`)
      if (data && data.data && data.data.product.length) {
        setCategories(data.data.product)
      } else {
      }
    } catch (error) {
        console.log("api error",error)
    }
  }

    return (
        <Layout>
        <div className="edu-event-area eduvibe-home-two-event edu-section-gap bg-color-white ">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <div className='section-title text-center'>
                        <span className="pre-title ">Re-Skilling Diploma</span>
                        <h3 className="title">{categorySlug.toUpperCase().replace(/-/g, ' ')}</h3>
                        </div>
                    </div>
                </div>
                <div className="row g-5 mt--25">
                    {categories.length===0 ? <ComingSoon/> : 
                        categories.map( ( item ) => (
                                <EventOne data={ item } bgWhite="enable" />
                        ) )
                    }
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-03-07.webp" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-02-04.webp" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-05-02.webp" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-4">
                        <img src="/images/shapes/shape-13-05.webp" alt="Shape Thumb" />
                    </div>
                    <div className="shape shape-1">
                        <span className="shape-dot"></span>
                    </div>
                </div>
            </div>
        </div>
          </Layout>
    )
}

export default HomeTwoEvents;