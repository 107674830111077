import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Select, { StylesConfig } from "react-select";
// import "../../assets/scss/components/jobportal/jobportalform.scss";
import { Button, Modal } from "react-bootstrap";
import FormSuccess from "../modals/FormSuccess";
import FormError from "../modals/FormError";

const Result = () => {
  return (
    <p className="success-message">
      Thanks for your query. We will contact with you soon.
    </p>
  );
};

function RegisterStudents(props) {
  const formStyle = props.formStyle;
  const [validated, setValidated] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const handleClose = () => setLgShow(false);
  const handleShow = () => setLgShow(true);
const [formSubmitted, setFormSubmitted] = useState(false)
const [formNotSubmitted, setFormNotSubmitted] = useState(false)
  const formDataError = {
    fullName: {
      message: "Enter full name",
    },
    email: {
      message: "Enter email",
    },
    phone_number: {
      message: "Enter phone number",
    },
    linked_in_profile: {
      message: "Enter a valid profile",
    },
    personal_website: {
      message: "Enter personal website",
    },

    qualification_level: {
      message: "Enter qualifier level",
    },
    location: {
      message: "Enter location ",
    },
    years_of_experience: {
      message: "Enter years of experience",
    },
    language_known: {
      message: "Enter language",
    },
    visa_status: {
      message: "Enter visibility status",
    },
    last_salary: {
      message: "Enter last salary",
    },

    expertise_area: {
      message: "Enter expertise Area",
    },
    proof_of_impact: {
      message: "Enter proof ofimpact",
    },
    profile_picture: {
      message: "Enter your Profile"
    },
    uploaded_cv: {
      message: 'Enter your CV'
    }

  };
  
  const formSubmitSuccessMessage = "Form successfully submitted";

  const [fullNameError, setFullNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [linkedInError, setLinkedInError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [websiteError, setWebsiteError] = useState(false);
  const [qualificationError, setQualificationError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [experienceError, setExperienceError] = useState(false);
  const [languageError, setLanguageError] = useState(false);
  const [visaError, setVisaError] = useState(false);
  const [salaryError, setSalaryError] = useState(false);
  const [expertiseAreaOptionsError, setExpertiseAreaOptionsError] = useState(false);
  const [proofOfImpactOptionsError, setProofOfImpactOptionsError] = useState(false);
  const [profilePictureError, setProfilePictureError] = useState(false);
  const [uploadedCvError, setUploadedCvError] = useState(false);




  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    linked_in_profile: "",
    personal_website: "",
    qualification_level: "",
    location: "",
    years_of_experience: "",
    expertise_area: [],
    language_known: "",
    last_salary: "",
    phone_number: "",
    proof_of_impact: [],
    visa_status: "",
    profile_picture: "",
    uploaded_cv: "",
  });

  const [countries, setCountries] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState("");
  const [selectedExpertiseAreas, setSelectedExpertiseAreas] = useState([]);
  const [selectedProofOfImpact, setSelectedProofOfImpact] = useState([]);
  const [selectedVisaStatus, setSelectedVisaStatus] = useState("");
  const [profileFileName, setProfileFileName] = useState("");
  const [cvName, setCvName] = useState("");

  const [yearsOfExperience] = useState([
    "0-1 years",
    "1-2 years",
    "3-5 years",
    "5-10 years",
    "10-15 years",
    "15-20 years",
    "20+ years",
  ]);

  const expertiseAreaOptions = [
    {
      value: "Accounting and Consulting",
      label: "Accounting and Consulting",
    },
    { value: "Admin Support", label: "Admin Support" },
    { value: "Customer Service", label: "Customer Service" },
    {
      value: "Data Science and Analytics",
      label: "Data Science and Analytics",
    },
    { value: "Design and Creative", label: "Design and Creative" },
    {
      value: "Engineering and Architecture",
      label: "Engineering and Architecture",
    },
    { value: "IT and Networking", label: "IT and Networking" },
    { value: "Legal", label: "Legal" },
    { value: "Sales and Marketing", label: "Sales and Marketing" },
    { value: "Translation", label: "Translation" },
    {
      value: "Web, Mobile and Software Dev",
      label: "Web, Mobile and Software Dev",
    },
    { value: "Writing", label: "Writing" },
  ];

  const [expertiseAreaOptionsSelected, setExpertiseAreaOptionsSelected] =
    useState([]);

  const proofOfImpactOptions = [
    { value: "Revenue Growth", label: "Revenue Growth" },
    { value: "Cost Reduction", label: "Cost Reduction" },
    { value: "Project Completion", label: "Project Completion" },
    {
      value: "Productivity Improvement",
      label: "Productivity Improvement",
    },
    { value: "Customer Satisfaction", label: "Customer Satisfaction" },
    { value: "Market Expansion", label: "Market Expansion" },
    {
      value: "Team Leadership and Development",
      label: "Team Leadership and Development",
    },
    { value: "Innovative Solution", label: "Innovative Solution" },
    { value: "Efficiency Enhancements", label: "Efficiency Enhancements" },
    { value: "Risk Management", label: "Risk Management" },
    { value: "Award Recognition", label: "Award Recognition" },
    { value: "Quality Improvement", label: "Quality Improvement" },
    { value: "Strategic Partnerships", label: "Strategic Partnerships" },
    { value: "Crisis Management", label: "Crisis Management" },
    {
      value: "Technological Implications",
      label: "Technological Implications",
    },
  ];

  const [proofOfImpactOptionsSelected, setProofOfImpactOptionsSelected] =
    useState([]);

  
  const [visaStatusOptions] = useState([
    "Visit in country",
    "Out of country",
    "Resident visa",
    "Employment visa",
  ]);

  const inputHandler = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectChange = (e) => {
    // console.log(e.target.name, e.target.value);

    setSelectedExperience(e.target.value);
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleExpertise = (e) => {
    setSelectedExpertiseAreas(e.target.value);
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleProof = (e) => {
    // console.log(e.target.name, e.target.value);
    setSelectedProofOfImpact(e.target.value);
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleVisa = (e) => {
    // console.log(e.target.name, e.target.value);
    setSelectedVisaStatus(e.target.value);
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  function profilePicInputHandler(event) {
    if (event.target.files) {
    //   console.log("ser", event);
      setProfileFileName(event.target.files[0].name);
      setFormData((prev) => ({
        ...prev,
        profile_picture: event.target.files[0],
      }));
    }
  }

  function cvInputHandler(event) {
    if (event.target.files) {
    //   console.log("ser", event);
      setCvName(event.target.files[0].name);
      setFormData((prev) => ({
        ...prev,
        uploaded_cv: event.target.files[0],
      }));
    }
  }

  const submitFormHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    // console.log(formData);
    const form = e.currentTarget;
    let flag = form.checkValidity();

    if (formData.full_name.length < 6) {
      setFullNameError(true);
      flag = flag && false;
    } else {
      setFullNameError(false);
    //   props.setShowToast(true);
    }

    
    if (
      formData.email.trim() == "" ||
      !formData.email
        .trim()
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setEmailError(true);
      flag = flag && false;
    } else {
      setEmailError(false);
    }

    if (
      formData.phone_number.trim() == "" ||
      !formData.phone_number
        .trim()
        .toLowerCase()
        .match(/^[789]\d{9}$/)
    ) {
      setPhoneError(true);
      flag = flag && false;
    } else {
      setPhoneError(false);
    }

    if (
      formData.linked_in_profile.trim() == "" ||
      !formData.linked_in_profile
        .trim()
        .toLowerCase()
        // .match(
        //   /(https?:\/\/(www.)|(www.))?linkedin.com\/(mwlite\/|m\/)?in\/[a-zA-Z0-9_.-]+\/?/
        // )
    ) {
      setLinkedInError(true);
      flag = flag && false;
    } else {
      setLinkedInError(false);
    }

    if (
      formData.personal_website.trim() == "" ||
      !formData.personal_website
        .trim()
        .toLowerCase()
        .match(
          /^(?:(?:http|https|ftp):\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=%]+$/
        )
    ) {
      setWebsiteError(true);
      flag = flag && false;
    } else {
      setWebsiteError(false);
    }

    if (
      formData.qualification_level.trim() == "" ||
      !formData.qualification_level
    ) {
      setQualificationError(true);
      flag = flag && false;
    } else {
      setQualificationError(false);
    }

    if (formData.location.trim() == "" || !formData.location) {
      setLocationError(true);
      flag = flag && false;
    } else {
      setLocationError(false);
    }

    if (
      formData.years_of_experience.trim() == "" ||
      !formData.years_of_experience
    ) {
      setExperienceError(true);
      flag = flag && false;
    } else {
      setExperienceError(false);
    }

    if (formData.language_known.trim() == "" || !formData.language_known) {
      setLanguageError(true);
      flag = flag && false;
    } else {
      setLanguageError(false);
    }

    if (formData.visa_status.trim() == "" || !formData.visa_status) {
      setVisaError(true);
      flag = flag && false;
    } else {
      setVisaError(false);
    }

    if (expertiseAreaOptionsSelected.length <= 0) {
      setExpertiseAreaOptionsError(true);
      flag = flag && false;
    } else {
      setExpertiseAreaOptionsError(false);
    }

    if (proofOfImpactOptionsSelected.length <= 0) {
      setProofOfImpactOptionsError(true);
      flag = flag && false;
    } else {
      setProofOfImpactOptionsError(false);
    }

    if (formData.last_salary.length <= 0) {
      setSalaryError(true);
      flag = flag && false;
    } else {
      setSalaryError(false);
    }


    if (formData.profile_picture == "") {
      setProfilePictureError(true);
      flag = flag && false;
    } else {
      setProfilePictureError(false);
    }

    if (formData.uploaded_cv == "") {
      setUploadedCvError(true);
      flag = flag && false;
    } else {
      setUploadedCvError(false);
    }


    if (flag) {
      try {
        const formDataToSend = new FormData();
        formDataToSend.append("full_name", formData.full_name);
        formDataToSend.append("email", formData.email);
        formDataToSend.append("phone_number", formData.phone_number);
        formDataToSend.append("linked_in_profile", formData.linked_in_profile);
        formDataToSend.append("personal_website", formData.personal_website);
        formDataToSend.append(
          "qualification_level",
          formData.qualification_level
        );
        formDataToSend.append("location", formData.location);
        formDataToSend.append(
          "years_of_experience",
          formData.years_of_experience
        );

        if (expertiseAreaOptionsSelected.length > 0) {
          for (let i = 0; i < expertiseAreaOptionsSelected.length; i++) {
            formDataToSend.append(
              `expertise_area[${i}]`,
              expertiseAreaOptionsSelected[i].value
            );  
          }
        }
    
        formDataToSend.append("language_known", formData.language_known);
        formDataToSend.append("last_salary", formData.last_salary);

        if (proofOfImpactOptionsSelected.length > 0) {
          for (let i = 0; i < proofOfImpactOptionsSelected.length; i++) {
            formDataToSend.append(
              `proof_of_impact[${i}]`,
              proofOfImpactOptionsSelected[i].value
            );
          }
        }
        formDataToSend.append("visa_status", formData.visa_status);
        formDataToSend.append("profile_picture", formData.profile_picture);
        formDataToSend.append("uploaded_cv", formData.uploaded_cv);

        // alert('Thank You for submitting')
        console.log("sending in api ->", formDataToSend);

        // Check if formDataToSend has data
        if (formDataToSend && formDataToSend.get("email")) {
          const response = await fetch(
            "https://certificatesglobal.com/backend/public/api/student/add",
            {
              method: "POST",
              body: formDataToSend,
            }
          );

          const responseData = await response.json();
          if (responseData.success) {
            // props.setShowToast(true);
            handleClose()
            setFormSubmitted(true)
          }
          console.log("API Response:", responseData);
        } else {
          setFormNotSubmitted(true)
          console.log("Form data is not complete. Cannot send request.");
        }
      } catch (error) {
        setFormNotSubmitted(true)
        console.error("Error while submitting form:", error);
      }
      
    }
  };


  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch(
          "https://certificatesglobal.com/backend/public/api/countries"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch countries");
        }
        const countryData = await response.json();
        setCountries(countryData.data);
        // console.log(countryData.data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };


    fetchCountries();
  }, []);
  
  const customeSelect = {
    control: (styles) => {
    //   console.log('styles', styles);
      return {
        ...styles,
        backgroundColor: "white",
        borderRadius: 12,
        minHeight: 32,
        fontSize: 13,
  
        ":hover": {
          color: "var(--bs-body-color)",
          backgroundColor: "var(--bs-body-bg)",
          borderColor: "#86b7fe",
          outline: 0,
          boxShadow: "0 0 0 0.25rem rgba(13, 110, 253, 0.25)",
        },
        ":active": {
          color: "var(--bs-body-color)",
          backgroundColor: "var(--bs-body-bg)",
          borderColor: "#86b7fe",
          outline: 0,
          boxShadow: "0 0 0 0.25rem rgba(13, 110, 253, 0.25)",
        },
        ":focus": {
          color: "var(--bs-body-color)",
          backgroundColor: "var(--bs-body-bg)",
          borderColor: "#86b7fe",
          outline: 0,
          boxShadow: "0 0 0 0.25rem rgba(13, 110, 253, 0.25)",
        },
        ":visited": {
          color: "var(--bs-body-color)",
          backgroundColor: "var(--bs-body-bg)",
          borderColor: "#86b7fe",
          outline: 0,
          boxShadow: "0 0 0 0.25rem rgba(13, 110, 253, 0.25)",
        },
      };
    },
    input: (styles) => {
    //   console.log("input-styles", styles);
      styles = { ...styles, paddingTop: 0, paddingBottom: 0, margin: 0 };
    //   console.log("input-styles", styles);
      return styles;
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //   console.log('option-styles', styles)
      return {
        ...styles,
        color: "black",
        cursor: isDisabled ? "not-allowed" : "default",
        ":active": {
          ...styles[":active"],
          backgroundColor: "var(--bs-primary)",
        },
      };
    },
  };
  

  return (
    <>
 
      <div className="container pb-5">
    <div className="row g-5 align-items-center">
      <div className="col-lg-12">
        <div className="read-more-btn text-end">
          <button className="edu-btn" onClick={handleShow}>
            Register<i className="icon-arrow-right-line-right"></i>
          </button>
        </div>
      </div>
    </div>
    </div>

      <Modal show={lgShow} onHide={handleClose} centered size='lg'>
        
        <Modal.Header className="bg-color-white ">
        {/* <h3 className="title">Student Registeration Form</h3> */}
        <img src="/images/job-portal.webp" style={{width:"100%"}}></img>
          {/* <Modal.Title>Register Student</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>

      <Form
        className={`${formStyle}`}
        action=""
        validated={validated}
        onSubmit={submitFormHandler}
      >
        <div className="container ">
          <div className="row">
            
            <div className="col-md-6 pt-3 " >
              <label htmlFor="validationCustom01" className="form-label" required>
                First Name *
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Name"
                onChange={inputHandler}
                name="full_name"
                required
              />
              {fullNameError && (
                <span className="text-danger">
                  {formDataError["fullName"]["message"]}
                </span>
              )}
            </div>

            <div className="col-md-6 pt-3">
              <label className="form-label">Email *</label>
              <input
                type="email"
                className="form-control form-control-lg"
                placeholder="Email"
                required
                onChange={inputHandler}
                name="email"
              />
              {emailError && (
                <span className="text-danger">
                  {formDataError["email"]["message"]}
                </span>
              )}
            </div>

            <div className="col-md-6 pt-3">
              <label className="form-label">Mobile No. *</label>
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Phone No."
                required
                onChange={inputHandler}
                name="phone_number"
              />
              {phoneError && (
                <span className="text-danger">
                  {formDataError["phone_number"]["message"]}
                </span>
              )}
            </div>

            <div className="col-md-6 pt-3">
              <label className="form-label">Linkedin Profile *</label>
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="LinkedIn"
                required
                onChange={inputHandler}
                name="linked_in_profile"
              />
              {linkedInError && (
                <span className="text-danger">
                  {formDataError["linked_in_profile"]["message"]}
                </span>
              )}
            </div>

            <div className="col-md-6 pt-3">
              <label className="form-label">Website *</label>
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Website"
                required
                onChange={inputHandler}
                name="personal_website"
              />
              {websiteError && (
                <span className="text-danger">
                  {formDataError["personal_website"]["message"]}
                </span>
              )}
            </div>

            <div className="col-md-6 pt-3">
              <label className="form-label">Qualification *</label>
              <select
                name="qualification_level"
                onChange={inputHandler}
                className="form-control form-control-lg rounded-4"
              >
                <option val="">- Select Qualification -</option>
                <option value="ug">Under Graduate</option>
                <option value="pg">Post Graduate</option>
              </select>
              {qualificationError && (
                <span className="text-danger">
                  {formDataError["qualification_level"]["message"]}
                </span>
              )}
            </div>

            <div className="col-md-6 pt-3">
              <label className="form-label">Location *</label>
              <select
                name="location"
                // style={{ padding: "0.6rem" }}
                onChange={inputHandler}
                className="form-control form-control-lg rounded-4"
              >
                <option val="">- Select Location -</option>
                {countries.map((country) => (
                  <option key={country.id} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
              {locationError && (
                <span className="text-danger">
                  {formDataError["location"]["message"]}
                </span>
              )}
            </div>

            <div className="col-md-6 pt-3">
              <label className="form-label">Experience *</label>
              <select
                name="years_of_experience"
                className="form-control form-control-lg rounded-4"
                onChange={handleSelectChange}
              >
                <option value="">Select...</option>
                {yearsOfExperience.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              {experienceError && (
                <span className="text-danger">
                  {formDataError["years_of_experience"]["message"]}
                </span>
              )}
            </div>

            <div className="col-md-6 pt-3">
              <label >Expertise Area *</label>
              <Select
                options={expertiseAreaOptions}
                
                isMulti
                onChange={(value) => {
                  setExpertiseAreaOptionsSelected(value);
                }}
                styles={customeSelect}
              ></Select>
              {expertiseAreaOptionsError && (
                <span className="text-danger">
                  {formDataError["expertise_area"]["message"]}
                </span>
              )}
            </div>

            <div className="col-md-6 pt-3">
              <label>Language *</label>
              <select
                // className="form-label"
                name="language_known"
                onChange={inputHandler}
                className="form-control form-control-lg rounded-4"
              >
                <option value="">Select...</option>

                <option>English</option>
                <option>Hindi</option>
              </select>

              {languageError && (
                <span className="text-danger">
                  {formDataError["language_known"]["message"]}
                </span>
              )}
            </div>

            <div className="col-md-6 pt-3">
              <label className="form-label">Salary *</label>
              <input
                type="text"
                placeholder="Salary"
                name="last_salary"
                onChange={inputHandler}
                required
              />
              {salaryError && (
                <span className="text-danger">
                  {formDataError["last_salary"]["message"]}
                </span>
              )}
            </div>

            
            <div
              className="col-md-6 pt-3"
            >
              <label >Proof of Impact *</label>

              <Select
                options={proofOfImpactOptions}
                isMulti
                value={proofOfImpactOptionsSelected}
                onChange={(value) => {
                //   console.log(value);
                  setProofOfImpactOptionsSelected(value);
                }}
                styles={customeSelect}

              ></Select>
              {proofOfImpactOptionsError && (
                <span className="text-danger">
                  {formDataError["proof_of_impact"]["message"]}
                </span>
              )}
            </div>

            <div className="col-md-6 pt-3">
              <label className="form-label">Visa Status *</label>
              <select
                name="visa_status"
                onChange={handleVisa}
                value={selectedVisaStatus}
                className="form-control form-control-lg rounded-4"

              // style={{ padding: "0.6rem" }}
              >
                <option value="">- Select Visa Status -</option>
                {visaStatusOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              {visaError && (
                <span className="text-danger">
                  {formDataError["language_known"]["message"]}
                </span>
              )}
            </div>

            <div className="col-md-6 pt-3">
              <div className="form-label">
                <label className="mb-1">Upload Profile *</label>
                <input
                  className="form-control form-control-lg rounded-4"
                  type="file"
                  name="profile_picture"
                  onChange={profilePicInputHandler}
                />
              </div>
              {profilePictureError && (
                <span className="text-danger">
                  {formDataError["profile_picture"]["message"]}
                </span>
              )}
            </div>
            
            <div className="col-md-6 pt-3">
              <div className="form-label">
                <label className="mb-1">Upload Resume *</label>
                <input
                  className="form-control form-control-lg rounded-4"
                  type="file"
                  name="uploaded_cv"
                  placeholder="Subject"
                  onChange={cvInputHandler}
                />
              </div>
              {uploadedCvError && (
                <span className="text-danger">
                  {formDataError["uploaded_cv"]["message"]}
                </span>
              )}
            </div>
           
          </div>
        </div>
      </Form>

        </Modal.Body>
        <Modal.Footer className=" px-5">
        <button style={{background:'#dc3545'}} className="edu-btn" onClick={handleClose}>
            Close
          </button>
          <button className="edu-btn ms-4" onClick={submitFormHandler ? submitFormHandler : undefined}>
            Register<i className="icon-arrow-right-line-right"></i>
          </button>
        </Modal.Footer>
      </Modal>



      {formSubmitted && 
     <FormSuccess/>}

      {formNotSubmitted && 
     <FormError/>}
    </>
  );
}
export default RegisterStudents;
