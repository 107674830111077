import React from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../sectionTitle/SectionTitle';
import { HashLink } from 'react-router-hash-link';

const features = [
    {
        icon: 'icon-award-fill',
        title: 'Tech Education'
    },
    {
        icon: 'icon-video-fill',
        title: 'Personalized Support'
    },
    {
        icon: 'icon-user-fill',
        title: 'Innovative Collaboration'
    },
    {
        icon: 'icon-flag-fill',
        title: 'Future Collaboration'
    }
];

const WhyChooseAbout = () => {
    return (
        <div className="eduvibe-home-three-video  edu-choose-us-area-one  choose-us-style-1 edu-section-gap bg-color-primary">
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-lg-6">
                        <div className="thumbnail">
                            <img src="/images/about/about-why-choose.webp" alt="Choose Us Images" width="719" height="612" loading="lazy"/>
                        </div>
                    </div>
        
                    <div className="col-xl-6 col-lg-6 offset-xl-1">
                        <div className="inner  mt_md--40 mt_sm--40">
                            <SectionTitle
                                classes = "text-start text-white"
                                slogan = "Why You Will Choose Guidence"
                                title = "Creating A Community Of Life Long Learners"
                            />

                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={ true }
                            >
                                <p className="line-before mb--5 text-white">Discover the power of guidance in fostering a community of lifelong learners, where support and mentorship propel personal and professional growth.</p>
                            </ScrollAnimation>
                            <div className="feature-style-2">
                                { features.map( ( item, i ) => (
                                    <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={ true }
                                        className="single-feature" 
                                        key={ i }
                                    >
                                        <div className="icon">
                                            <i className={ item.icon }></i>
                                        </div>
                                        <div className="content">
                                            <h6 className="feature-title text-white">{ item.title }</h6>
                                        </div>
                                    </ScrollAnimation>
                                ) ) }
                            </div>
                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                className="read-more-btn mt--60"
                                animateOnce={ true }
                            >
                                <HashLink className="edu-btn btn-dark" to="/#categories">Re-Skilling Diploma Courses<i className="icon-arrow-right-line-right"></i></HashLink>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyChooseAbout;