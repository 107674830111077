import React from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram } from 'react-icons/fa';
import ScrollTopButton from './ScrollTopButton';

const Footer = () => {
    return (
        <>
            <footer className="eduvibe-footer-one edu-footer footer-style-default bg-color-primary">
                <div className="footer-top">
                    <div className="container eduvibe-animated-shape">
                        <div className="row g-5">
                            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="edu-footer-widget">
                                    <div className="logo">
                                        <Link to={process.env.PUBLIC_URL + "/"}>
                                            <img className="logo-light" src="/images/logo/logo.webp" alt="Footer Logo" width="150" height="38" loading='lazy'/>
                                        </Link>
                                    </div>
                                    <p className="description">Committed To Learn Excellence In Education</p>
                                    <ul className="social-share">
                                        <li><a href="https://www.facebook.com/seedingbrains" target='blank' aria-label="Seeding Brains Facebook Profile"><i className="icon-Fb"></i></a></li>
                                        <li><a href="https://www.linkedin.com" target='blank'><i className="icon-linkedin" aria-label="Seeding Brains LinkedIn Profile"></i></a></li>
                                        <li><a href="https://www.instagram.com/seeding_brains/" target='blank' aria-label="Seeding Brains Instagram Profile"><FaInstagram style={{fontSize:"22px"}} /></a></li>
                                        {/* <li><a href="#"><i className="icon-Twitter"></i></a></li> */}
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget explore-widget">
                                    <h4 className="widget-title">Explore</h4>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            <li><Link to="/about"><i className="icon-Double-arrow"></i>About Us</Link></li>
                                            <li><Link to="/sed-news"><i className="icon-Double-arrow"></i>SED News</Link></li>
                                            <li><Link to="/htbd"><i className="icon-Double-arrow"></i>HTBD</Link></li>
                                            {/* <li><Link to="/faq"><i className="icon-Double-arrow"></i>FAQ Question</Link></li> */}
                                            {/* <li><Link to="/testimonial"><i className="icon-Double-arrow"></i>Testimonial</Link></li> */}
                                            {/* <li><Link to="#"><i className="icon-Double-arrow"></i>Privacy Policy</Link></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget quick-link-widget">
                                    <h4 className="widget-title">Useful Links</h4>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            <li><Link to="/contact"><i className="icon-Double-arrow"></i>Contact Us</Link></li>
                                            <li><Link to="/job-portal"><i className="icon-Double-arrow"></i>Job Portal</Link></li>
                                            <li><Link to="/guest-lecture"><i className="icon-Double-arrow"></i>Guest Lecture</Link></li>
                                            {/* <li><Link to="/purchase-guide"><i className="icon-Double-arrow"></i>Purchase Guide</Link></li>
                                            <li><Link to="/course-1"><i className="icon-Double-arrow"></i>Popular Courses</Link></li>
                                            <li><Link to="/event-details/1"><i className="icon-Double-arrow"></i>Event Details</Link></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget">
                                    <h4 className="widget-title">Contact Info</h4>
                                    <div className="inner">
                                        <div className="widget-information">
                                            <ul className="information-list">
                                                <li><i className="icon-map-pin-line"></i>173, M Floor, <br/>Unique World Business Centre, <br/>Al Karama, Dubai
                                                </li>
                                                <li><i className="icon-phone-fill"></i><a href="tel: +971 50 538 3739">+971 50 538 3739</a></li>
                                                {/* <li><i className="icon-phone-fill"></i><a href="tel: + 1 (237) 382-2840">+ 1 (237) 382-2840</a></li> */}
                                                <li><i className="icon-mail-line-2"></i><a target="_blank" href="mailto:info@certificateglobal.com">info@certificateglobal.com</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="shape-dot-wrapper shape-wrapper d-md-block d-none">
                            <div className="shape-image shape-image-1">
                                <img src="/images/shapes/shape-21-01.webp" alt="Shape Thumb" width="160" height="160" loading='lazy'/>
                            </div>
                            <div className="shape-image shape-image-2">
                                <img src="/images/shapes/shape-35.webp" alt="Shape Thumb" width="122" height="123" loading='lazy'/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-area copyright-default">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <p>Copyright 2024 <a href="/">Seeding Brains.</a> All Rights
                                        Reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <ScrollTopButton />
        </>
    )
}

export default Footer;