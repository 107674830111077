import React from 'react';

const LogoOne = ( { separator }) => {
    return (
        <div className={`edu-brand-area edu-section-gapBottom bg-color-white ${ separator !== 'disable' ? 'edu-line-separator' : '' }`}>
            <div className="container">
                <div className="row eduvibe-home-five-brands">
                    <div className="col-lg-12 col-md-12 text-center mb--30">
                        <div className="client-logo">
                            <img className='logo-main' src='/images/home/powered-by-antai/antai.webp' alt='antai-logo' width="233" height="100" loading='lazy'/>
                            <img className="logo-hover" src="/images/home/powered-by-antai/antai.webp" alt="antai-logo" width="233" height="100" loading='lazy'/>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                        <a href="https://www.antaifintechglobal.com/mcast/" className="client-logo" target='_blank'>
                            <img className="logo-main" src="/images/home/powered-by-antai/mcast.webp" alt="Brand Images" width="200" height="100" loading='lazy'/>
                            <img className="logo-hover" src="/images/home/powered-by-antai/mcast.webp" alt="Brand Images" width="200" height="100" loading='lazy'/>
                        </a>
                    </div>   
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                        <a href="https://www.antaifintechglobal.com/manipal-university/" className="client-logo mt-2" target='_blank'>
                            <img className="logo-main" src="/images/home/powered-by-antai/manipal-university.webp" alt="Brand Images" width="200" height="100" loading='lazy'/>
                            <img className="logo-hover" src="/images/home/powered-by-antai/manipal-university.webp" alt="Brand Images" width="200" height="100" loading='lazy'/>
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                        <a href="#" className="client-logo" >
                            <img className="logo-main" src="/images/home/powered-by-antai/british-council.webp" alt="Brand Images" width="200" height="100" loading='lazy'/>
                            <img className="logo-hover" src="/images/home/powered-by-antai/british-council.webp" alt="Brand Images" width="200" height="100" loading='lazy'/>
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                        <a href="#" className="client-logo" >
                            <img className="logo-main" src="/images/home/powered-by-antai/fdp.webp" alt="Brand Images" width="200" height="100" loading='lazy'/>
                            <img className="logo-hover" src="/images/home/powered-by-antai/fdp.webp" alt="Brand Images" width="200" height="100" loading='lazy'/>
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                        <a href="#" className="client-logo" >
                            <img className="logo-main" src="/images/home/powered-by-antai/onnix.webp" alt="Brand Images" width="200" height="100" loading='lazy'/>
                            <img className="logo-hover" src="/images/home/powered-by-antai/onnix.webp" alt="Brand Images" width="200" height="100" loading='lazy'/>
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                        <a href="#" className="client-logo" >
                            <img className="logo-main" src="/images/home/powered-by-antai/vscc.webp" alt="Brand Images" width="200" height="100" loading='lazy'/>
                            <img className="logo-hover" src="/images/home/powered-by-antai/vscc.webp" alt="Brand Images" width="200" height="100" loading='lazy'/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LogoOne;