import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import ScrollAnimation from "react-animate-on-scroll";
import SectionTitle from "../sectionTitle/SectionTitle";
import { FaPlay } from "react-icons/fa";
import { FaUsers } from "react-icons/fa6";

const items = [
  {
    title: "Tech-Driven Learning",
    info: "Immerse in VR classrooms, AI-driven pathways, and gamified modules for an interactive and personalized learning journey.",
    icon: "icon-research",
  },
  {
    title: "Community Collaboration",
    info: "Engage in peer discussions, group projects, and co-creation opportunities to foster knowledge exchange and collective growth.",
    icon: "FaUsers",
  },
  {
    title: "Future Education",
    info: "Stay ahead with emerging technologies and pedagogical approaches, ensuring readiness for tomorrows challenges and opportunities.",
    icon: "icon-student",
  },
  {
    title: "Innovative Learning",
    info: "Experience a dynamic shift from conventional methods, transforming education through innovation, collaboration, and continuous evolution.",
    icon: "icon-clock",
  },
];

const WhyChooseUsSection = () => {
  const [toggler, setToggler] = useState(false);
  const videoLink = ["https://www.youtube.com/watch?v=pNje3bWz7V8"];
  return (
    <div className="edu-feature-area eduvibe-home-one-video edu-section-gap bg-color-white">
      <div className="container eduvibe-animated-shape">
        <div className="row row--35">
          <div className="col-lg-5 col-12 order-2 order-lg-1">
            <div className="inner mt_md--40 mt_sm--40">
              <SectionTitle
                classes="text-start"
                slogan="Why Choose US"
                title="Our Core Features"
              />
              <div className="feature-list-wrapper mt--10">
                {items.map((data, i) => (
                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    className="feature-list mt--35 mt_mobile--15"
                    animateOnce={true}
                    key={i}
                  >
                    <div className="icon">
                      {data.icon === "FaUsers" ? (
                        <FaUsers
                          style={{ fontSize: "50px", color: "#f86f03" }}
                        />
                      ) : (
                        <i className={data.icon}></i>
                      )}
                    </div>
                    <div className="content">
                      <div className="title title-text">{data.title}</div>
                      <p>{data.info}</p>
                    </div>
                  </ScrollAnimation>
                ))}
              </div>
            </div>
          </div>

          <div className="col-lg-7 col-12 order-1 order-lg-2">
            <div className="feature-thumbnail">
              <div className="main-image video-popup-wrapper video-popup-two">
                <img
                  src={`${process.env.PUBLIC_URL}./images/videopopup/why-choose-image.webp`}
                  alt="Video PopUp Thumb"
                  width="632" 
                  height="598"
                  loading='lazy'
                />

              </div>

              <div className="circle-image">
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
          <div className="shape-image shape-image-1">
            <img src="/images/shapes/shape-14.webp" alt="Shape Thumb" width="77" height="80" loading='lazy'/>
          </div>
          <div className="shape-image shape-image-2">
            <img src="/images/shapes/shape-11-01.webp" alt="Shape Thumb" width="101" height="88" loading='lazy'/>
          </div>
          <div className="shape-image shape-image-3">
            <img src="/images/shapes/shape-15.webp" alt="Shape Thumb" width="54" height="59" loading='lazy'/>
          </div>
        </div>
      </div>
      <div className="video-lightbox-wrapper">
        <FsLightbox
          toggler={toggler}
          sources={videoLink}
          maxYoutubeVideoDimensions={{ width: 900, height: 550 }}
        />
      </div>
    </div>
  );
};

export default WhyChooseUsSection;
