import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
const FormError = () => {
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
            
        <div className="modal-body text-center p-lg-4">
        <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 130.2 130.2"
              style={{width: "100px",display: "block",margin: "0 auto" }}
            >
              <circle
                className="path circle"
                fill="none"
                stroke="#db3646"
                strokeWidth={6}
                strokeMiterlimit={10}
                cx="65.1"
                cy="65.1"
                r="62.1"
              />
              <line
                className="path line"
                fill="none"
                stroke="#db3646"
                strokeWidth={6}
                strokeLinecap="round"
                strokeMiterlimit={10}
                x1="34.4"
                y1="37.9"
                x2="95.8"
                y2="92.3"
              />
              <line
                className="path line"
                fill="none"
                stroke="#db3646"
                strokeWidth={6}
                strokeLinecap="round"
                strokeMiterlimit={10}
                x1="95.8"
                y1={38}
                x2="34.4"
                y2="92.2"
              />
            </svg>
            <h4 className="text-danger mt-3">Something went wrong !</h4>
            <p className="mt-3">
            Sorry, there was an error submitting the form. <br/> Please try again later.
            </p>
            <button
          type="button"
          className="btn btn-lg mt-3 fs-3 btn-danger"
          data-bs-dismiss="modal"
          onClick={handleClose}
        >
          Ok
        </button>
      </div>
          
          </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
    </Modal>
  )
}

export default FormError
