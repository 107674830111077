import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';
import axios from 'axios';

// const items = [
//     {
//         title: 'Business Management',
//         numberOfCourses: '2 Diploma',
//         image: '1.webp',
//         shape: 'shape-bg-1',
//         link: '/category-product/business-management'
//     },
//     {
//         title: 'Project Management',
//         numberOfCourses: 'Coming Soon',
//         image: '2.webp',
//         shape: 'shape-bg-2',
//         link: '/category-product/project-management'
//     },
//     {
//         title: 'Python Programing',
//         numberOfCourses: 'Coming Soon',
//         image: '3.webp',
//         shape: 'shape-bg-3',
//         link: '/category-product/python-programming'
//     },
//     {
//         title: 'Risk Management',
//         numberOfCourses: 'Coming Soon',
//         image: '4.webp',
//         shape: 'shape-bg-4',
//         link: '/category-product/risk-management'
//     },
//     {
//         title: 'IT & Computing RSD',
//         numberOfCourses: '1 Diploma',
//         image: '5.webp',
//         shape: 'shape-bg-5',
//         link: '/category-product/it-computing-rsd'
//     },
//     {
//         title: 'Health & Social Care',
//         numberOfCourses: 'Coming Soon',
//         image: '6.webp',
//         shape: 'shape-bg-3',
//         link: '/category-product/healt-and-social-care'
//     }
// ];

const PrevArrow = ( props ) => {
    const { onClick } = props;
    return <button className="slide-arrow prev-arrow" onClick={ onClick } id="previousDiploma" aria-label='Previous Diploma'><i className="icon-arrow-left-line"></i></button>
}

const NextArrow = (props) => {
    const { onClick } = props;
    return <button className="slide-arrow next-arrow" onClick={ onClick } id='nextDiploma'  aria-label='Next Diploma'><i className="icon-arrow-right-line"></i></button>
}

const HomeCategory = ( { wrapperClass, itemClass } ) => {
    const [items,setItems] = useState([]);
    const sliderSettings = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4
                }
            }, 
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }, 
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }, 
            {
                breakpoint: 577,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    useEffect(()=>{
const fetchCategories = async()=>{
    const response = await axios.get('https://certificatesglobal.com/backend/public/api/product-categories')
    const data = response.data.data.collection
    console.log(response.data.data.collection)
    setItems(data)
}
fetchCategories()
    },[])

    return (
        <Slider 
            className={ wrapperClass || 'col-lg-12 mt--60 mb_dec--20 slick-activation-wrapper service-activation-item5 edu-slick-arrow-top' }
            { ...sliderSettings }
        >
            { items.map( ( data , i ) => (
                <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    className={ itemClass || 'single-slick-card' }
                    animateOnce={ true }
                    initiallyVisible ={true}
                    key={ i }
                >
                    <div className={`service-card service-card-8 shape-bg-${i+1}`}>
                        <div className="inner">
                            <div className="icon" style={{height:"75px", width:"75px"}}>
                                <Link to={`/category-product/${ data.slug }`}>
                                    <img src={ data.image} alt="Category Seeding Brains"  width="125" height="125" loading='lazy'/>
                                </Link>
                            </div>
                            <div className="content">
                                <div className="title"><a href={`/category-product/${ data.slug }`}>{ data.name }</a></div>
                                <span>{ data.description.replace(/<[^>]*>/gm, '') }</span>
                            </div>
                            <div className="hover-action">
                                <Link className="read-more-btn" to={`/category-product/${ data.slug }`} aria-label="Learn More"><i className="icon-arrow-right-line-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
            ) ) }
        </Slider>
    )
}

export default HomeCategory;