import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import Breadcrumb from "../../common/breadcrumb/Breadcrumb";
import ContactUsForm from "./ContactUsForm";

const ContactUs = () => {
  return (
    <>
      <SEO title="Contact Us" />
      <Layout>
        <Breadcrumb
          title="Contact Us"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Contact Us"
        />
        <div className="eduvibe-contact-us edu-contact-us-area edu-section-gap bg-color-white">
          <div className="container eduvibe-animated-shape">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="pre-title">Need Help?</span>
                  <h3 className="title">Get In Touch With us</h3>
                </div>
              </div>
            </div>
            <div className="row g-5 mt--20">
              <div className="col-lg-12">
                <div className="contact-info pr--70 pr_lg--0 pr_md--0 pr_sm--0">
                  <div className="row g-5">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="contact-address-card-1 website">
                        <div className="inner">
                          <div className="icon">
                            <i className="ri-global-line"></i>
                          </div>
                          <div className="content">
                            <h6 className="title">Our Website</h6>
                            <p>
                              <a href="https://certificatesglobal.com/">
                                www.certificatesglobal.com
                              </a>
                            </p>
                            {/* <p><a href="https://themeforest.net/user/devsvibe">www.wpvive.com</a></p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="contact-address-card-1 phone">
                        <div className="inner">
                          <div className="icon">
                            <i className="icon-Headphone"></i>
                          </div>
                          <div className="content">
                            <h6 className="title">Call Us On</h6>
                            <p>
                              <a href="tel: +971 50 538 3739">
                                +971 50 538 3739
                              </a>
                            </p>
                            {/* <p><a href="tel: +4875 (356) 2568">+4875 (356) 2568</a></p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="contact-address-card-1 email">
                        <div className="inner">
                          <div className="icon">
                            <i className="icon-mail-open-line"></i>
                          </div>
                          <div className="content">
                            <h6 className="title">Email Us</h6>
                            <p>
                              <a href="mailto:info@certificateglobal.com">
                                info@certificateglobal.com
                              </a>
                            </p>
                            {/* <p><a href="mailto:wpvive@mail.com">wpvive@mail.com</a></p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="contact-address-card-1 location">
                        <div className="inner">
                          <div className="icon">
                            <i className="icon-map-pin-line"></i>
                          </div>
                          <div className="content">
                            <h6 className="title">Our Location</h6>
                            <p>
                              173, M Floor, Unique World Business Centre, Al
                              Karama, Dubai
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6">
                <ContactUsForm formStyle="rnt-contact-form rwt-dynamic-form row" />
              </div> */}
            </div>

            <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
              <div className="shape-image shape-image-1">
                <img src="/images/shapes/shape-04-01.webp" alt="Shape Thumb" />
              </div>
              <div className="shape-image shape-image-2">
                <img src="/images/shapes/shape-02-08.webp" alt="Shape Thumb" />
              </div>
              <div className="shape-image shape-image-3">
                <img src="/images/shapes/shape-15.webp" alt="Shape Thumb" />
              </div>
            </div>
          </div>
        </div>

        <div className="edu-contact-map-area edu-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="google-map alignwide">
                  <iframe
                    title="Google Map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7217.580763517036!2d55.30080624498892!3d25.24398429494063!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f430c3e625201%3A0xa2604945fc37e07d!2sUnique%20World%20Business%20Center%20LLC%20(Branch)!5e0!3m2!1sen!2sin!4v1710919515892!5m2!1sen!2sin"
                    height="500"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default ContactUs;
