import React from 'react';
import { AiOutlineCheckSquare,AiFillCheckSquare } from "react-icons/ai";
import { useJobsContext } from '../../context/jobs_context';
import { FaRegCircle } from "react-icons/fa";
import { FaCircleDot } from "react-icons/fa6";


const JobFilters = () => {

    const {filterHandler,filters,resetFilters,} = useJobsContext()

    return (
        <aside className="edu-course-sidebar">
                {Object.keys(filters).map((name) => (
                        <div key={name} className="edu-course-widget widget-shortby mb-5 ">
                            <div className='inner'>
                                <h5 className="widget-title">{name}</h5>
                                {filters[name].map((filter,i) => (
                                 <div className="edu-form-check" key={i}>
                                  <p
                                    key={filter.name}
                                    className={`availability ${
                                      filter.selected ? 'selected' : 'not-selected'
                                    }`}
                                    onClick={() =>
                                      filterHandler({ type: name, name: filter.name })
                                    }
                                  >
                                    {!filter.selected && (
                                      
                                      <FaRegCircle
                                        fontSize={20}
                                        style={{ marginRight: 10 }}
                                      />
                                    )}
                                    {filter.selected && (
                                      <FaCircleDot
                                        fontSize={20}
                                        style={{ marginRight: 10 }}
                                      />
                                    )}
                                    {filter.name}
                                  </p>
                                  </div>
                                ))}
                          </div>
                        </div>
                      ))}

            <button type='button' className="edu-btn btn-medium mt--40" onClick={ resetFilters }>
                Reset All Filters
            </button>
        </aside>
    )
}


export default JobFilters