import React from 'react'
import { Col, Row } from "react-bootstrap";
import { useGuestsContext } from '../../context/guests-context';


const InputForm = () => {
    const {showForm,setShowForm} = useGuestsContext()
    console.log(showForm,"dfnshj")
  return (

<div className='InputForm animate-bottom'>
<div className='container'>
  <div className='close-icon'>
    <img
      // src={Close}
      alt='closeButton'
      onClick={() => setShowForm(false)}
      className='cursor-pointer'
    ></img>
  </div>
  <div style={{ textAlign: 'center', marginBottom: 24 }}>
    <h2 style={{ color: 'green', fontWeight: 'bold', fontSize: '34px' }}>
      {/* {ActionName} */}
    </h2>
    <p
      className='bottomNoteText'
      style={{ color: '#3d995b', fontWeight: 'bold' }}
    >
      Register as a guest lecturer with your industry expertise to teach
      in the higher education sector
    </p>
  </div>
  <div className='inner-container'>
    <div className='input-form-left-container' style={{ padding: 70 }}>
      <img  className='img-fluid' alt='logo' />
    </div>
    <div className='input-form-right-container'>
      <form action=''  className=''>
        {/* {error && (
          <Alert variant='danger' style={{ display: 'flex' }}>
            {error}
          </Alert>
        )} */}

      <Row>
      <Col md={6}>
        <div className='custom-input-group'>
          <label>Name *</label>
          <input
            type='text'
            placeholder='John Doe'
            name='user_name'
            
          />
        </div>
      </Col>
      <Col md={6}>
        <div className='custom-input-group'>
          <label>Email *</label>
          <input
            type='email'
            placeholder='example@xyz.com'
            name='user_email'
            
          />
        </div>
      </Col>
      <Col md={6}>
        <div className='custom-input-group'>
          <label>Mobile *</label>
          <input
            type='text'
            placeholder='9999999999'
            name='mobile_no'
            
          />
        </div>
      </Col>
      <Col md={6}>
        <div className='custom-input-group'>
          <label>Job Type *</label>
          <select
            name='job_type'
            style={{ padding: '0.6rem' }}
            
          >
            <option val=''>- Select Job Type -</option>
            {/* {jobTypeOptions.map((value, key) => (
              <option key={key} value={value}>
                {value}
              </option>
            ))} */}
          </select>
        </div>
      </Col>
      {/* {jobTypeOther && (
        <Col md={6}>
          <div className='custom-input-group'>
            <label>Please Specify *</label>

            <input
              type='text'
              placeholder='Manager'
              name='job_type_other'
              
            />
          </div>
        </Col>
      )} */}
      <Col md={6}>
        <div className='custom-input-group'>
          <label>Teaching Mode *</label>

          <select name='teaching_mode' >
            <option value=''>- Select Mode -</option>
            {/* {['Face-To-Face', 'Online'].map((mode) => (
              <option key={mode} value={mode}>
                {mode}
              </option>
            ))} */}
          </select>
        </div>
      </Col>
      <Col md={6}>
        <div className='custom-input-group'>
          <label>Choose Availability *</label>
          {/* <WeekdaySelector
            name='availability'
            title='Choose Availability'
            
          /> */}
        </div>
      </Col>
      <Col md={6}>
        <div className='custom-input-group'>
          <label>Choose Time Slot *</label>
          {/* <WeekdaySelector
            name='time_slot'
            title='Choose Time Slot'
            Values={['9AM-12PM', '12PM-3PM', '3PM-6PM', '6PM-9PM']}
            
          /> */}
        </div>
      </Col>
      <Col md={6}>
        <div className='custom-input-group'>
          <label>Select Expertise *</label>
          <select name='expertise' >
            <option val=''>- Select Expertise -</option>
            {/* {['IT', 'Business', 'Other'].map((mode) => (
              <option key={mode} value={mode}>
                {mode}
              </option>
            ))} */}
          </select>
        </div>
      </Col>
      {/* {expertise && (
        <Col md={6}>
          <div className='custom-input-group'>
            <label>Please Specify *</label>
            <input
              type='text'
              placeholder='Marketing'
              name='expertise_other'
              
            />
          </div>
        </Col>
      )} */}
      <Col md={6}>
        <div className='custom-input-group'>
          <label>Experience *</label>
          <input
            type='text'
            placeholder='2 Years'
            name='experience'
            
          />
        </div>
      </Col>
      <Col md={6}>
        <div className='custom-input-group'>
          <label>Select Emirate/City *</label>
          <select name='emirates' >
            <option val=''>- Select Emirate/City -</option>
            {/* {cities.map((mode) => (
              <option key={mode} value={mode}>
                {mode}
              </option>
            ))} */}
          </select>
        </div>
      </Col>
      <Col md={6}>
        <div className='custom-input-group'>
          <label>Upload Your Resume *</label>
          <div
            className='input'
        
            style={{
              wordWrap: 'break-word',
              wordBreak: 'break-word',
            }}
          >
          </div>
          <input
            id='uploadCV'
            style={{ display: 'none' }}
            type='file'
            name='uploaded_cv'
          />
        </div>
      </Col>
      <Col md={6}>
        <div className='custom-input-group'>
          <label>Upload Profile Picture *</label>
          <div
            className='input'
         
            style={{
              wordWrap: 'break-word',
              wordBreak: 'break-word',
            }}
          >
            {/* {!profileFileName ? 'Profile Picture' : profileFileName} */}
          </div>
          <input
            id='profilePIC'
            style={{ display: 'none' }}
            type='file'
            name='profile_pic'
          />
        </div>
      </Col>
    </Row>

  <div className='captchaBox'>
          {/* <Recaptcha
            sitekey='6Lc34ycmAAAAAE4XuOczDOAUWWNGIfOecd6WnJQd'
            onChange={succesHandler}
          /> */}
        </div>

        <div className='d-flex'>
          {(
            <button
              className='RegButton mt-2 disabled'
              type='submit'
              onClick={(e) => {
                e.preventDefault()
              }}
            >
              Please Wait
            </button>
          )}

          {(
            <button className='RegButton mt-2' type='submit'>
              Submit
            </button>
          )}
        </div>
      </form>
    </div>
  </div>
</div>
</div>
  )
}

export default InputForm
