import React from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../sectionTitle/SectionTitle';
import { Element } from 'react-scroll';

const items = [
    {
        title: 'Flexible Learning',
        info: ' You can choose when to learn, how to learn, and what to learn in a way that suits you.',
        icon: 'icon-Hand---Book'
    },
    {
        title: 'Anywhere Education',
        info: ' Access learning materials and join classes from any location, making education accessible wherever you are.',
        icon: 'icon-Campus'
    }
];

const AboutOne = () => {
    return (
        <Element
            name="about-us"
            className="edu-about-area about-style-1 edu-section-gap bg-color-white"
        >
            <div className="container">
                <div className="row g-5">
                    <div className="col-lg-6">
                        <div className="about-image-gallery">
                            <img className="image-1" src="/images/about/abt-main.webp" alt="About Main Thumb" width="470" height="520" loading='lazy'/>
                            <div className="image-2">
                                <img src="/images/about/about-2.webp" alt="About Parallax Thumb" width="220" height="257" loading='lazy'/>
                            </div>
                            <div className="badge-inner">
                                <img className="image-3" src="/images/about/badge.webp" alt="About Circle Thumb" width="125" height="125" loading='lazy'/>
                            </div>
                            <div className="shape-image shape-image-1">
                                <img src="/images/shapes/shape-04-01.webp" alt="Shape Thumb" width="87" height="116" loading='lazy'/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="inner">
                            <SectionTitle
                                slogan = "About Us"
                                title = " Innovating Education: Bridging Gaps, Empowering Learners."
                            />
                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={ true }
                            >
                                <p className="description">Revolutionizing education through innovative technology, collaborative engagement, and a future-forward approach for dynamic learning experiences.</p>
                            </ScrollAnimation>
                            { items && items.length > 0 &&
                                <div className="about-feature-list">
                                    { items.map( ( data , i ) => (
                                        <ScrollAnimation 
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            className="our-feature"
                                            animateOnce={ true }
                                            key={i}
                                        >
                                            <div className="our-feature">
                                                <div className="icon">
                                                    <i className={ data.icon }></i>
                                                </div>
                                                <div className="feature-content">
                                                    <div className="feature-title text-black">{ data.title }</div>
                                                    <p className="feature-description">{ data.info }</p>
                                                </div>
                                            </div>
                                        </ScrollAnimation>
                                    ) ) }
                                </div>
                            }
                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                className="read-more-btn"
                                animateOnce={ true }
                            >
                                <Link className="edu-btn" to="/about">Know About Us<i className="icon-arrow-right-line-right"></i></Link>
                            </ScrollAnimation>
                            <div className="shape shape-6 about-parallax-2 d-xl-block d-none">
                                <img src="/images/shapes/shape-07.webp" alt="Shape Thumb" width="45" height="117" loading='lazy'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Element>
    )
}

export default AboutOne;