import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../sectionTitle/SectionTitle';

const CallToActionHTBD = () => {
    return (
        <>
        <div className="edu-newsletter-area newsletter-overlay-to-another">
            <div className="container newsletter-style-5 bg-color-primary">
                <div className="row align-items-center g-5">
                    <div className="col-lg-7">
                        <div className="inner">
                            <SectionTitle
                                classes = "text-start text-white"
                                slogan = " Insights for Success"
                                title = "Customer Behavior in UAE"
                            />
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="cta-btn text-start text-lg-end">
                            <Link className="edu-btn btn-white" to="https://www.wordbank.com/uk/blog/consumer-behaviour-in-uae/" target='_blank'>Know More<i className="icon-arrow-right-line-right"></i></Link>
                        </div>
                    </div>
                </div>
            
            </div>
            
        </div>
        <div className="edu-newsletter-area mt--40">
            <div className="container newsletter-style-5 bg-color-primary">
                <div className="row align-items-center g-5">
                <div className="col-lg-5">
                        <div className="cta-btn text-start text-lg-start">
                            <Link className="edu-btn btn-white" to="https://files.eric.ed.gov/fulltext/EJ1056632.pdf" target='_blank'>Know More<i className="icon-arrow-right-line-right"></i></Link>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="inner">
                            <SectionTitle
                                classes = "text-start text-white"
                                slogan = " Insights for Succeed"
                                title = "Importance Of Marketing"
                            />
                        </div>
                    </div>
                  
                </div>
            
            </div>
            
        </div>
        <div className="edu-newsletter-area mt--40">
            <div className="container newsletter-style-5 bg-color-primary">
                <div className="row align-items-center g-5">
               
                    <div className="col-lg-7">
                        <div className="inner">
                            <SectionTitle
                                classes = "text-start text-white"
                                slogan = " Insightful Connections"
                                title = "Behavioral Segmentation"
                            />
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="cta-btn text-start text-lg-end">
                            <Link className="edu-btn btn-white" to="https://nanopdf.com/download/behavioral-segmentation-tm_pdf" target='_blank'>Know More<i className="icon-arrow-right-line-right"></i></Link>
                        </div>
                    </div>
                  
                </div>
            
            </div>
            
        </div>

        </>
    )
}

export default CallToActionHTBD;