import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { HashLink } from 'react-router-hash-link';
import Slider from 'react-slick';

const items = [
    {
        title: 'Dubai Alert',
        description: "Explore Dubai's amenities but don't miss an IBP session; it could chill you out and affect your grades.",
        image: 'dubai-alert.webp',
        shape: 'shape-bg-1',
        link: '#academicPolicies'
    },
    {
        title: 'Agenda Importance',
        description: 'Enjoy weekly agendas for a smooth course journey. Incomplete MCQs and reflections could lower your final grade.',
        image: 'agenda-importance.webp',
        shape: 'shape-bg-2',
        link: '#academicPolicies'
    },
    {
        title: 'Original Reflections',
        description: 'Your reflections should be your own and avoid any act of plagiarism in your submission.',
        image: 'original-reflection.webp',
        shape: 'shape-bg-3',
        link: '#academicPolicies'
    },
    {
        title: 'Course Access',
        description: 'You can access the course only for 5 weeks from the date of start. You cannot edit your answer once submitted.',
        image: 'course-access.webp',
        shape: 'shape-bg-4',
        link: '#academicPolicies'
    },
    {
        title: 'Learning Modes',
        description: 'Your learning will be of three kinds: asynchronous, Live sessions, and virtual field visits.',
        image: 'learning-modes.webp',
        shape: 'shape-bg-5',
        link: '#academicPolicies'
    },
    {
        title: 'Academic Queries',
        description: 'For queries related to academic policies, contact: bem@seedingbrains.com/Mob: +971529607869.',
        image: 'academic-queries.webp',
        shape: 'shape-bg-3',
        link: '#academicPolicies'
    },
    {
        title: 'Office Hours',
        description: 'Office hours: 10:00 am to 5:00 pm, Monday to Friday, provide ample opportunity for consultation and support.',
        image: 'office-hours.webp',
        shape: 'shape-bg-3',
        link: '#academicPolicies'
    }
];
const PrevArrow = ( props ) => {
    const { onClick } = props;
    return <button className="slide-arrow prev-arrow" onClick={ onClick }><i className="icon-arrow-left-line"></i></button>
}

const NextArrow = (props) => {
    const { onClick } = props;
    return <button className="slide-arrow next-arrow" onClick={ onClick }><i className="icon-arrow-right-line"></i></button>
}

const FeatureTwo = ( { wrapperClass, itemClass } ) => {
    const sliderSettings = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3
                }
            }, 
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }, 
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }, 
            {
                breakpoint: 577,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <Slider 
            className={ wrapperClass || 'col-lg-12 mt--60 mb_dec--20 slick-activation-wrapper service-activation-item5 edu-slick-arrow-top' }
            { ...sliderSettings }
        >
            { items.map( ( data , i ) => (
                <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    className={'single-slick-card' }
                    animateOnce={ true }
                    key={ i }
                >
                    <div className={`service-card service-card-8 ${data.shape}`}>
                        <div className="inner">
                            <div className="icon">
                                <HashLink to={ data.link }>
                                    <img src={`${process.env.PUBLIC_URL}./images/htbd/academic-policies/${ data.image }`} alt="Category Thumb" />
                                </HashLink>
                            </div>
                            <div className="content">
                                <h6 className="title"><a href={ data.link }>{ data.title }</a></h6>
                                <span>{ data.description }</span>
                            </div>
                            <div className="hover-action">
                                <HashLink className="read-more-btn" to={ data.link }><i className="icon-arrow-right-line-right"></i></HashLink>
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
            ) ) }
        </Slider>
    )
}

export default FeatureTwo;