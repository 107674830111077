import React, { useState } from 'react';
 const policies = [
        {
            id: 1,
            name: 'Anti-racism Policy',
            price: '$29.99',
            priceType: 'Monthly',
            buttonText: 'Buy This Plan',
            purchaseLink: '#',
            features: [
                'Combat racism and bullying',
                'Report incidents promptly.',
                'Enforce strict consequences.',
                'Ensure safe learning environment.',
                'Zero tolerance for discrimination.'
            ]
        },
        {
            id: 2,
            name: 'Appeal Policy',
            price: '$39.99',
            priceType: 'Monthly',
            buttonText: 'Buy This Plan',
            purchaseLink: '#',
            active: true,
            badgeText: 'POPULAR',
            features: [
                'Contact manager for appeal form.',
                'Submit form as instructed.',
                'Await appeal result communication.',
                'Keep contact details updated.',
                "Follow manager's guidance.",
                'Keep manager informed regularly.'
            ]
        },
        {
            id: 3,
            name: 'Contingency & Adverse Policy',
            price: '$49.99',
            priceType: 'Monthly',
            buttonText: 'Buy This Plan',
            purchaseLink: '#',
            features: [
                'Plan for contingencies established.',
                'Contact program manager for exceptions.',
                'Discuss situation and action plan.',
                'Seek guidance from program manager.',
                'Implement agreed-upon plan.'
            ]
        }
    ];
    

const OurPolicies = () => {

    return (
        <>
                <div className="edu-pricing-area edu-section-gap bg-image">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-6">
                                <div className="section-title text-start">
                                    <span className="pre-title">Our Policies</span>
                                    <h3 className="title">Policies & Guidelines</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row g-5 mt--20">
                            { policies.map( (data, index) => (
                                    <div className="col-lg-4 col-md-6 col-12" key={ index }>
                                        <div className={ `pricing-table ${data.active === true ? ' active' : ''}` }>
                                            <div className="pricing-header">
                                                {/* {
                                                    data.active === true && <div className="edu-badge"><span>{ data.badgeText }</span></div>
                                                } */}
                                                <h3 className="title">{ data.name }</h3>
                                                {/* <div className="price-wrap">
                                                    <div className="yearly-pricing">
                                                        <span className="amount">{ data.price }</span>
                                                        <span className="duration">/{ data.priceType }</span>
                                                    </div>
                                                </div> */}
                                            </div>
                                            { data.features && data.features.length > 0 && 
                                                <div className="pricing-body">
                                                    <ul className="list-item">
                                                        {
                                                            data.features.map( ( feature, i ) => <li key={i}><i className="icon-checkbox-circle-line"></i>{ feature }</li> )
                                                        }
                                                    </ul>
                                                </div>
                                            }
                                            {/* <div className="pricing-btn">
                                                <a className={ `edu-btn ${data.active !== true ? ' btn-dark' : ''}` } href={ data.purchaseLink }>
                                                    { data.buttonText }<i className="icon-arrow-right-line-right"></i>
                                                </a>
                                            </div> */}
                                        </div>
                                    </div>
                                ) )
                            }
                            
                        </div>
                    </div>
                </div>
        </>
    )
}

export default OurPolicies;