import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';
import SectionTitle from '../sectionTitle/SectionTitle';
import { useNewsContext } from '../../context/news_context';
import PostTwo from '../post/PostTwo';

const HomeBlog = () => {
    const {filtered_news} = useNewsContext()
    return (
        <div className="edu-blog-area eduvibe-home-one-blog edu-section-gap bg-image" >
            <div className="container eduvibe-animated-shape">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-6">
                        <SectionTitle
                            classes = "text-start"
                            slogan = "BLog & News"
                            title = "Trending on Our Blogs"
                        />
                    </div>
                    <div className="col-lg-6">
                        <div className="read-more-btn text-end">
                            <Link className="edu-btn" to="/sed-news">Read More Blogs<i className="icon-arrow-right-line-right"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="row g-5 mt--30">
                    { 
                        filtered_news.slice( 0, 2 ).map((item) => (
                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}
                                className="col-lg-6 col-md-6 col-sm-6 col-12"
                                key={ item.id } 
                            >
                                <PostTwo data={item} bgWhite="enable" />
                            </ScrollAnimation>
                        ) )
                    }
                </div>
                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-04-02.webp" alt="Shape Thumb" width="59" height="125" loading='lazy'/>
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-15-02.webp" alt="Shape Thumb" width="54" height="59" loading='lazy'/>
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-13-03.webp" alt="Shape Thumb" width="106" height="127" loading='lazy'/>
                    </div>
                    <div className="shape-image shape-image-4">
                        <img src="/images/shapes/shape-17.webp" alt="Shape Thumb" width="141" height="113" loading='lazy'/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeBlog;