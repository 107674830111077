import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const EventOne = ({ data, bgWhite }) => {
    const defaultDescription = (data.description.replace(/<[^>]*>|&nbsp;|&rsquo;/gm, '').substring(0, 500))
    const [description, setDescription] = useState(defaultDescription)
    const [isLessDetail, setIsLessDetail] = useState(false)

    const handleDetail = ()=>{
        setIsLessDetail((lessDetail)=> lessDetail? false : true)
        if(isLessDetail){
            setDescription(defaultDescription)
        }
        else{
            setDescription(data.description.replace(/<[^>]*>|&nbsp;|&rsquo;/gm, ''))
        }
    }
    return (
        <div className={`edu-event event-list radius-small ${bgWhite === 'enable' ? 'bg-white' : ''}`}>
            <div className="inner">
                <div className="thumbnail">
                    <Link to="#">
                        <img src={data.featured_image} alt="Event Thumb" />
                    </Link>
                </div>
                <div className="content">
                    <div className="content-left">
                        <h5 className="title">
                            <Link to="#">{data.name}</Link>
                        </h5>
                        <p className='description'>{description}</p>
                        <ul className="event-meta">
                            <li><i className="icon-calendar-2-line"></i>{data.updated_at.substring(0, 10)}</li>
                            <li><i className="icon-dollor"></i> {data.sale_price}</li>
                            {/* <li><i className="icon-map-pin-line"></i>{data.location}</li> */}
                        </ul>
                        <div className="read-more-btn mt-5 text-right">
                            
                                <button className="edu-btn" onClick={handleDetail}>{isLessDetail ? "Less Detail" : "More Detail"}<i className="icon-arrow-right-line-right"></i></button>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default EventOne;