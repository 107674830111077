import React from 'react'
import { useJobsContext } from '../../context/jobs_context';

const SortJob = ( { showPerPage } ) => {
    // const {
    //     filtered_jobs: jobs,
    //     searchbyjob,
    //     updateSearch
    // } = useJobFilterContext();
    const {searchHandler, jobs,searchbyjob} = useJobsContext()
    let visibleItems = showPerPage;
    const totalJobs = jobs.length;
    if ( totalJobs <= visibleItems ) {
        visibleItems = totalJobs;
    }

    const sortTitle = 'Showing <span>' + visibleItems + '</span> of <span>' + totalJobs +'</span> Jobs';
    
    return (
        <div className="row g-5 align-items-center">
            <div className="col-lg-4 col-md-6 col-12">
                <div className="short-by">
                    <p dangerouslySetInnerHTML={{__html: sortTitle}}></p>
                </div>
            </div>
            <div className="col-lg-8 col-md-6 col-12">
                <div className="edu-search-box-wrapper text-start text-md-end">
                    <div className="edu-search-box">
                        <form action="#">
                            <input
                                type='text'
                                name='searchbyjob'
                                id='searchbyjob'
                                placeholder='Search Job...'
                                className="search-input"
                                value={ searchbyjob }
                                onChange={ searchHandler }
                            />
                            <button className="search-button">
                                <i className="icon-search-line"></i>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SortJob;