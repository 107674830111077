import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import Breadcrumb from "../../common/breadcrumb/Breadcrumb";
import GuestItemsForFilter from "../../components/guest-lecture/GuestItemsForFilter";
import GuestFilters from "../../components/guest-lecture/GuestFilter";
import { GuestsProvider } from "../../context/guests-context";
import RegisterGuests from "../../components/guest-lecture/RegisterGuests";
import TrackVisibility from "react-on-screen";
import Skill from "../../components/progressbar/ProgressbarItem";

const GuestLecture = () => {

  const percent = 30 
  return (
    <>
      <SEO title="Guest Lecture" />
      <Layout>
        <Breadcrumb
          title="Guest Lecture"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Guest Lecture"
        />

        <GuestsProvider>
          <div className="edu-course-area edu-section-gap bg-color-white pt-2">
            <div className="container">
              <div className="rbt-progress-style-1 row g-5">
                <div className="col-lg-8">
                  <TrackVisibility once className="single-progress">
                    <Skill progress={{ title: "Teachers", percent: percent }} />
                  </TrackVisibility>
                </div>
                <div className="col-lg-4">
                  <RegisterGuests />
                </div>
              </div>
              <div className="row g-5">
                <div className="col-lg-8">
                  <GuestItemsForFilter />
                </div>
                <div className="col-lg-4">
                  <GuestFilters />
                </div>
              </div>
            </div>
          </div>
        </GuestsProvider>
      </Layout>
    </>
  );
};

export default GuestLecture;
