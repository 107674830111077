const news_reducer = ( state, action ) => {
    if ( action.type === 'GET_NEWS_BEGIN' ) {
        return { 
            ...state, 
            news_loading: true 
        }
    }

    if ( action.type === 'GET_NEWS_SUCCESS' ) {
        return {
            ...state,
            news_loading: false,
            news: action.payload,
            filtered_news:[...action.payload.data],
            pagination:action.payload.pagination,
        }
    }
    if(action.type === 'GET_RIGHT_SIDEBAR_NEWS'){
        return{
            ...state,
            sidebarBlogs: action.payload
        }
    }
    if(action.type === 'GET_CATEGORY_NEWS'){
        return{
            ...state,
            categoryBlogs: action.payload
        }
    }

    if ( action.type === 'GET_NEWS_ERROR' ) {
        return { 
            ...state, 
            news_loading: false, 
            news_error: true 
        }
    }

    throw new Error( `No Matching '${action.type}' - action type found.` );
}
  
export default news_reducer;