import React from 'react';
import Slider from 'react-slick';
import ExpertsData from '../../data/experts/ExpertsData';
import ExpertTwo from '../../components/experts/ExpertTwo';

const PrevArrow = ( props ) => {
    const { onClick } = props;
    return <button className="slide-arrow prev-arrow" onClick={ onClick }><i className="icon-arrow-left-line"></i></button>
}

const NextArrow = (props) => {
    const { onClick } = props;
    return <button className="slide-arrow next-arrow" onClick={ onClick }><i className="icon-arrow-right-line"></i></button>
}

const HTBDExpert = ( { wrapperClass } ) => {
   
    return (
        <>
                <div className="edu-elements-area edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="pre-title">EXPERTS</span>
                                    <h3 className="title">HTBD INSIGHTS STRAIGHT FROM EXPERTS</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row g-5 mt--5">
                            { 
                                ExpertsData.map((item) => (
                                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt--45" key={item.name}>
                                        <ExpertTwo data={item} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
        </>
    )
}

export default HTBDExpert;