import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const AwardItems = [
    {
        subtitle: 'Week One',
        title: 'See Dubai',
        image: 'see-dubai.webp',
        shortDescription:"In the first week, you will study the geographic segmentation of the Dubai market."
    },
    {
        subtitle: 'Week Two',
        title: 'Know Dubai',
        image: 'know-dubai.webp',
        shortDescription:"In the second week, you will study the socio cultural segmentation of the Dubai market."
    },
    {
        subtitle: 'Week Three',   
        title: 'Meet Dubai',
        image: 'meet-dubai.webp',
        shortDescription:"In the third week, you will get into firmographic segmentation, meeting the business honchos."
    },   
    {
        subtitle: 'Final Week',
        title: 'Feel and live Dubai.',
        image: 'feel-dubai.webp',
        shortDescription:"You will get an understanding of Dubai's cultural, and demographic segmentation."
    }
];

const SegmentExploration = () => {
    return (
        <div className="eduvibe-about-two-award edu-winner-area edu-section-gap bg-color-white">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title text-center">
                            <span className="pre-title">Dive into Dubai</span>
                            <h3 className="title"> A Segmented Exploration</h3>
                        </div>
                    </div>
                </div>
                <div className="row g-5 mt--20">
                    { AwardItems.map( ( data , i ) => (
                        <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            className="col-lg-3 col-md-6 col-12"
                            animateOnce={ true }
                            key={ i }
                        >
                            <div className="edu-winner-card">
                                <div className="inner">
                                    <div className="icon">
                                        <img src={`${process.env.PUBLIC_URL}/images/htbd/exploration/${ data.image }`} alt="Award Icon" />
                                    </div>
                                    <div className="content">
                                        <span className="subtitle">{ data.subtitle }</span>
                                        <h6 className="title">{ data.title }</h6>
                                        <p>{data.shortDescription}</p>
                                    </div>
                                </div>
                            </div>
                        </ScrollAnimation>
                    ) ) }
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-03-07.webp" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-04-01.webp" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-25-02.webp" alt="Shape Thumb" />
                    </div>
                    <div className="shape shape-1">
                        <span className="shape-dot"></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SegmentExploration;