const guests_reducer = ( state, action ) => {
    if ( action.type === 'GET_GUESTS_BEGIN' ) {
        return { 
            ...state, 
            guests_loading: true 
        }
    }

    if ( action.type === 'GET_GUESTS_SUCCESS' ) {

        return {
            ...state,
            guests_loading: false,
            guests: action.payload.guestsData,
            pagination : action.payload.pagination,
            // page:action.payload.page,
            // filter_guests:action.payload.guestsData
        }
    }

    if ( action.type === 'GET_GUESTS_ERROR' ) {
        return { 
            ...state, 
            guests_loading: false, 
            guests_error: true 
        }
    }

    if(action.type === 'FILTER_GUESTS'){
        return{
            ...state,
            filter_guests:action.payload.guestsData,
        }
    }

    throw new Error( `No Matching '${action.type}' - action type found.` );
}
  
export default guests_reducer;