import React, { useState, useEffect } from "react";
import JobTypeThree from "./JobTypeThree";
import SortJob from "./SortJob";
import { useJobsContext } from "../../context/jobs_context";

const JobItemsForFilter = () => {
  // const { filtered_jobs } = useJobFilterContext();
  const { pagination, page, setPage, jobs} = useJobsContext();
  const pageItems = Array.from(
    { length: pagination.last_page },
    (_, index) => index + 1
  );

  const jobsPerPage = pagination.per_page;
  const data = jobs;

  const handlePage = async(pageNumber) => {
    try {
      if (page !== pageNumber) {
        setPage(pageNumber)
        // await applyFilter({ filters }, pageNumber)
      }
    } catch (error) {
      console.log('An error occured please try after some time',error)
    }
    // setPage(pageNumber);
  };

  const nextPage = () => {
    console.log("next btn clicked")
    console.log(pagination.last_page)
    console.log(page) 
    if (pagination.last_page !== page) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    console.log(page)
    if (page !== 1) {
      setPage(page - 1);
    }
  };

  return (
    <>
      <SortJob showPerPage={jobsPerPage} />

      <div className="row g-5 mt--10">
        {data !== undefined &&
          data.map((job) => {
            return (
              <div key={job.id} className="col-lg-6 col-md-6">
                <JobTypeThree data={job} />
              </div>
            );
          })}
      </div>

      {pagination.total > 1 && (
        <div className="row">
          <div className="col-lg-12 mt--60">
            <div className="nav">
              <ul className="edu-pagination">
                <li className="prev-btn">
                  <button onClick={prevPage}>
                    <i className="ri-arrow-drop-left-line"></i>
                  </button>
                </li>

                {pageItems.map((pageNumber) => (
                  <li
                    key={pageNumber}
                    className={`page-item ${
                      pagination.current_page === pageNumber ? "active" : ""
                    }`}
                  >
                    <button onClick={() => handlePage(pageNumber)}>
                      {pageNumber}
                    </button>
                  </li>
                ))}

                <li className="next-btn">
                  <button onClick={nextPage}>
                    <i className="ri-arrow-drop-right-line"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default JobItemsForFilter;
