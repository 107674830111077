import React from 'react';
import SectionTitle from '../sectionTitle/SectionTitle';
import TestimonialOne from '../testimonial/TestimonialOne';


const TestimonialSectionOne = () => {
    return (
        <div className="eduvibe-testimonial-one edu-testimonial-area edu-section-gap bg-image">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <SectionTitle
                            classes = "text-center"
                            slogan = "Testimonial"
                            title = "What Our Student Says"
                        />
                    </div>
                </div>
                <div className="row g-5 mt--25">
                    <div className="col-lg-6 col-12">
                        <div className="satisfied-learner">
                            <div className="thumbnail">
                                <img src="/images/testimonial/main.webp" alt="Education Images" width="500" height="550" loading='lazy'/>
                            </div>
                            <div className="trophy-content bounce-slide">
                                <div className="icon">
                                    <img src="/images/testimonial-section/trophy.webp" alt="Trophy Images" width="49" height="49" loading='lazy'/>
                                </div>
                                <div className="content">
                                    <p className="text title-text">50K+</p>
                                    <span>Satisfied Learners</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <TestimonialOne item="3" />
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-13.webp" alt="Shape Thumb" width="68" height="74" loading='lazy'/>
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-13-01.webp" alt="Shape Thumb" width="143" height="130" loading='lazy'/>
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-04.webp" alt="Shape Thumb" width="113" height="150" loading='lazy'/>
                    </div>
                    <div className="shape-image shape-image-4">
                        <img src="/images/shapes/shape-02-01.webp" alt="Shape Thumb" width="68" height="73" loading='lazy'/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TestimonialSectionOne;