import React from 'react';
import Slider from 'react-slick';
import { TestimonialTwoParams } from '../../utils/script';

const data = [
    {
        name: " Sarah M.",
        details: "Seeding Brains' immersive tech and collaboration make learning thrilling and equipped for the future of education. It's a game-changer.",
        designation: "SEO Expert",
        image: "student.webp",
        rating: 5
    },
    {
        name: "Kevin G. Curry",
        details: "Collaboration and innovation at Seeding Brains forge a dynamic and promising educational landscape for all participants. It's revolutionary.",
        designation: "Web Developer",
        image: "student.webp",
        rating: 5
    },
    {
        name: "David M. Bard",
        details: "Seeding Brains ignites innovation, fostering connections and pushing the boundaries of education with like-minded individuals. It's transformative.",
        designation: "Content Writer",
        image: "student.webp",
        rating: 5
    },
    {
        name: "Julia H. Smith",
        details: "Equipped with essential digital skills from Seeding Brains, I stay ahead, poised for success in the evolving educational realm. It's empowering.",
        designation: "Shopify Expert",
        image: "student.webp",
        rating: 5
    },
    {
        name: "Jeffery C. Meyer",
        details: "In Seeding Brains' environment, diverse perspectives and inventive solutions enrich the learning journey, fueling growth and exploration. It's inspiring.",
        designation: "WordPress Expert",
        image: "student.webp",
        rating: 5
    },
    {
        name: "Robert E. Carter",
        details: "Transforms learning into an engaging adventure through gamified modules and personalized pathways tailored for individual success. It's captivating.",
        designation: "SEO Expert",
        image: "student.webp",
        rating: 5
    },
    {
        name: "Emily G. Harper",
        details: "Seeding Brains seamlessly integrates innovation and education, providing a vibrant platform for perpetual learning and advancement. It's visionary.",
        designation: "Digital Marketer",
        image: "student.webp",
        rating: 3
    }
];

const TestimonialTwo = ( props ) => {
    const classes = `eduvibe-testimonial-four edu-testimonial-activation testimonial-item-3 mt--40 edu-slick-button ${props.className ? props.className : '' }`;
    return (
        <Slider
            className={classes}
            { ...TestimonialTwoParams }
        >
            { data.map( ( item, i ) => (
                <div className="testimonial-card-box" key={ i }>
                    <div className="inner">
                        <div className="client-info">
                            <div className="thumbnail">
                                <img src={`${process.env.PUBLIC_URL}/images/sed-about/testimonial/${item.image}`} alt="Client Thumb" width="55" height="55" loading="lazy"/>
                            </div>
                            <div className="content">
                                <h6 className="title">{item.name}</h6>
                                <span className="designation">Students</span>
                            </div>
                        </div>
                        <p className="description">“ {item.details} ”</p>
                        <div className="rating eduvibe-course-rating-stars">
                            {[...Array(5)].map((_, index) => {
                                return <i key={index} className={ index < item.rating ? 'on icon-Star' : 'off icon-Star' }></i>
                            })}
                        </div>
                    </div>
                </div>
            ) ) }
        </Slider>
    )
}

export default TestimonialTwo;