import React from 'react'
import { Link } from 'react-router-dom'
import { slugify } from '../../utils'

const Category = ({ data, classes, bgWhite }) => {
  return (
    <div className={`edu-blog blog-type-2 radius-small ${classes ? classes : '' } ${bgWhite === 'enable' ? 'bg-color-white' : '' }`} aria-hidden="true">
    <div className="inner">
        <div className="thumbnail">
            <Link to={data.link}>
                <img src={`${process.env.PUBLIC_URL}./images/category/qualification-logo/${data.image}`} alt="Blog Thumb" loading={data.loading_type} alt={data.image_alt} width="370" height="270"/>
            </Link>
        </div>
        <div className="content">
            {/* <div className="status-group">
                <Link className="eduvibe-status status-05" to={process.env.PUBLIC_URL + `/category/${ slugify( data.categories.slice( 0, 1 ) ) }`}>
                    <i className="icon-price-tag-3-line"></i> {data.categories.slice(0, 1)}
                </Link>
            </div> */}
            <div className="title text-center fs-1 ">
                <Link to={data.link} target="_blank">{data.title}</Link>
            </div>
            {/* <div className="blog-card-bottom">
                <ul className="blog-meta">
                    <li><i className="icon-calendar-2-line"></i>{ data.date }</li>
                </ul>
                <div className="read-more-btn">
                    <Link className="btn-transparent" to={process.env.PUBLIC_URL + `/blog-details/${data.id}`}>Read More<i className="icon-arrow-right-line-right"></i></Link>
                </div>
            </div> */}
        </div>
    </div>
</div>
  )
}

export default Category
