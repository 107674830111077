import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import HTBDBanner from "../../components/htbd/HTBDBanner";
import HTBDExpert from "../innerpages/HTBDExpert";
import AboutHTBD from "../../components/htbd/AboutHTBD";
import SegmentExploration from "../../components/htbd/SegmentExploration";
import LMS from "../../components/htbd/LMS";
import EvaluationCriteria from "../../components/htbd/EvaluationCriteria";
import PopularEvidence from "../../components/htbd/PopularEvidence";
import GradingCriteria from "../../components/htbd/GradingCriteria";
import AcademicPolicies from "../../components/htbd/AcademicPolicies";
import CallToActionHTBD from "../../components/cta/CallToActionHTBD";
import OurPolicies from "../../components/htbd/OurPolicies";

const HTBD = () => {
  return (
    <>
      <SEO title="How to Do Business in Dubai" preload="/images/htbd/htbd-video-banner.webp" />
      <Layout>
        <HTBDBanner />

        <HTBDExpert />

        <AboutHTBD />

        <SegmentExploration />

        <LMS />

        <EvaluationCriteria/>

        <PopularEvidence/>

        <GradingCriteria/>

        <AcademicPolicies/>

        <CallToActionHTBD/>

        <OurPolicies/>
      </Layout>
    </>
  );
};

export default HTBD;
