import React from 'react';
import SectionTitle from '../sectionTitle/SectionTitle';
import HomeCategory from '../category/HomeCategory';

const HomeCategories = () => {
    return (
        
        <div id="categories" className="eduvibe-home-five-cats edu-about-area about-style-5 edu-section-gap bg-color-white">
            <div className="container eduvibe-animated-shape">
                <div className="row g-5">
                    <div className="col-lg-12">
                        {/* <SectionTitle
                             
                            slogan = "Discover Your Mind"
                            title = "Re-Skilling Diploma"
                        /> */}
                          <div className={`section-title`}>
                          <span className="pre-title" >Discover Your Mind</span>
                          <h3 className="title" >Re-Skilling Diploma</h3>
                          </div>
                    </div>
                </div>
                <div className="row">
                    <HomeCategory />
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-07.webp" alt="Shape Thumb" width="45" height="117" loading='lazy'/>
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-03-05.webp" alt="Shape Thumb" width="109" height="98" loading='lazy'/>
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-04-06.webp" alt="Shape Thumb" width="75" height="100" loading='lazy'/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeCategories;