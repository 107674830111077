import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Slider from 'react-slick';
import ExpertOne from '../../components/experts/ExpertOne';
import ExpertsData from '../../data/experts/ExpertsData.json'

const PrevArrow = ( props ) => {
    const { onClick } = props;
    return <button className="slide-arrow prev-arrow" onClick={ onClick }><i className="icon-arrow-left-line"></i></button>
}

const NextArrow = (props) => {
    const { onClick } = props;
    return <button className="slide-arrow next-arrow" onClick={ onClick }><i className="icon-arrow-right-line"></i></button>
}

const AboutUsExpert = ({wrapperClass}) => {
    const sliderSettings = {
        dots: true,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        centerMode: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false
                }
            }, 
            {
                breakpoint: 577,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                    arrows: false
                }
            }
        ]
    };
    return (
        <>
        <div className="edu-team-carousel-area edu-section-gap">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-12">
                    <div className="section-title">
                        <span className="pre-title">Expertise Empowering Education</span>
                        <h3 className="title">Our Experts</h3>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <Slider 
                        className={ wrapperClass || 'team-activation-01 edu-slick-arrow-top edu-slick-button slick-gutter-15 mt--60 mb--50 mb_dec--20 pb--40' }
                        { ...sliderSettings }
                    >
                        { 
                            ExpertsData.map( ( item ) => (
                                <ExpertOne key={ item.id } data={ item } classes='instructor-one-each-slide' />
                            ) )
                        }
                    </Slider>
                </div>
            </div>
        </div>
    </div>
    </>
    )
}

export default AboutUsExpert;