import React from 'react'
import { useGuestsContext } from '../../context/guests-context';


const SortGuest = ( { showPerPage } ) => {
    const {
        guests,
        searchbyguest,
        updateSearch
    } = useGuestsContext();
    let visibleItems = showPerPage;
    const totalGuests = guests.length;
    if ( totalGuests <= visibleItems ) {
        visibleItems = totalGuests;
    }

    const {searchHandler} = useGuestsContext()
    const sortTitle = 'Showing <span>' + visibleItems + '</span> of <span>' + totalGuests +'</span> Guests';
    
    return (
        <div className="row g-5 align-items-center">
            <div className="col-lg-4 col-md-6 col-12">
                <div className="short-by">
                    <p dangerouslySetInnerHTML={{__html: sortTitle}}></p>
                </div>
            </div>
            <div className="col-lg-8 col-md-6 col-12">
                <div className="edu-search-box-wrapper text-start text-md-end">
                    <div className="edu-search-box">
                        <form action="#">
                            <input
                                type='text'
                                name='searchbyguest'
                                id='searchbyguest'
                                placeholder='Search Guest...'
                                className="search-input"
                                value={ searchbyguest }
                                onChange={ searchHandler }
                            />
                            <button className="search-button">
                                <i className="icon-search-line"></i>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SortGuest;