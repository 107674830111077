import React from 'react';
import CountUp from 'react-countup';
import ScrollAnimation from 'react-animate-on-scroll';
import TrackVisibility from 'react-on-screen';
import SectionTitle from '../sectionTitle/SectionTitle';

const FeatureItems = [
    {
        title: 'Your Learning',
        description:" You will be able to take this learning to access the resources laid out by The Dubai city",
        number: 449,
        image: 'about-image-01.webp'
    },
    {
        title: 'Your Graps',
        description:"Students will understand the relevant resources available for the community.",
        number: 330,
        image: 'about-image-02.webp'
    },
    {
        title: 'Skilful Knowledge',
        description:"You’ll know about building start-ups in Dubai. Knowledge of geopolitical resources.",
        number: 275,
        image: 'about-image-03.webp'
    },
    {
        title: 'Skill Acquisition',
        description:"Networking skills Start-up business skills Understanding Arabic cultural skills.",
        number: 378,
        image: 'about-image-04.webp'
    }
];

const AboutHTBD = ( { wrapperClass } ) => {
    return (
        <div className={`eduvibe-home-two-counter bg-color-primary eduvibe-home-three-video ${ wrapperClass || 'edu-section-gap' }`}>
            <div className="container eduvibe-animated-shape">
                <div className="row align-items-center gy-5">
                    <div className="col-lg-6">
                        <div className="row g-5 pr--75">
                            { FeatureItems.map( ( data , i ) => (
                                <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    className="col-lg-6 col-md-6 col-sm-6 col-12"
                                    animateOnce={ true }
                                    key={ i }
                                >
                                    <div className="edu-counterup">
                                        <div className="inner">
                                            <div className="icon">
                                                <img src={`${process.env.PUBLIC_URL}./images/about/about-01/${ data.image }`} alt="Icon Thumb" />
                                            </div>
                                            <div className="content">
                                                {/* <TrackVisibility once className="counter" tag="h3">
                                                    { ( { isVisible } ) => isVisible && 
                                                        isVisible ? <CountUp className="eduvibe-about-counterup" end={data.number} duration={1.5} delay={.1} start={0} /> : ''
                                                        
                                                    }
                                                </TrackVisibility> */}
                                                <h6 className="title">{data.title}</h6>
                                                <span>{data.description}</span>
                                            </div>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            ) ) }
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="choose-us-2">
                            <div className="inner">
                                <SectionTitle
                                    classes = "text-left text-white"
                                    slogan = "Why Choose Us"
                                    title = "Goal to achieve through HTBD course"
                                />
                                <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={ true }
                                >
                                    <p className="description mt--40 mb--30 text-white">Understanding the placement of resource allocation by the Dubai government across the city, geographically.</p>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    className="feature-style-4"
                                    animateOnce={ true }
                                >
                                    <div className="edu-feature-list">
                                        <div className="icon">
                                        <img src={`${process.env.PUBLIC_URL}./images/about/about-01/smile.webp`} alt="Icon Thumb" />
                                        </div>
                                        <div className="content">
                                            <h6 className="title text-white">Strategic Resource Allocation</h6>
                                            <p className='text-white'>Gain insights into Dubai's governmental resource distribution for optimized urban development through geographic analysis.</p>
                                        </div>
                                    </div>

                                    <div className="edu-feature-list ">
                                        <div className="icon">
                                            <i className="icon-Support"></i>
                                        </div>
                                        <div className="content">
                                            <h6 className="title text-white">Data-Driven Decision Making</h6>
                                            <p className='text-white'>Learn how to make informed choices by analyzing data on Dubai's resource allocation for city development.</p>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-04-01.webp" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-11.webp" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutHTBD;