import React from "react";
import { FaInstagram } from "react-icons/fa";

const AboutSeedingBrains = (props) => {
  return (
    <div
      className={`edu-blog-widget ${
        props.style2 === "enable" ? "edu-about-widget-2" : ""
      } widget-about ${props.extraClass || ""}`}
    >
      <div className="inner">
        <h5 className="widget-title">About Me</h5>
        <div className="content">
          <div className="about-wrapper">
            <div className="thumbnail">
              <img
                src="/images/widget/about/about-logo-1.webp"
                alt="Blog Images"
              />
            </div>
            <div className="about-content">
              <h6 className="title">Seeding Brains</h6>
              <p>
                Seeding brains fosters intellectual growth, sparking curiosity
                and nurturing creativity, leading to innovation, wisdom, and
                enriched societies worldwide
              </p>
              <ul className="social-share icon-transparent justify-content-center">
                <li>
                  <a
                    href="https://www.facebook.com/seedingbrains"
                    target="_blank"
                  >
                    <i className="icon-Fb"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com" target="_blank">
                    <i className="icon-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/seeding_brains/"
                    target="_blank"
                  >
                    <FaInstagram style={{ fontSize: "22px" }} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSeedingBrains;
