import React from 'react';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import Category from '../category/Category';
import Slider from 'react-slick';
import { BlogCarouselParams } from '../../utils/script';
const items = [
    {
      title: "AGI",
      info: "Lorem Ipsum is simply for this dummy text of the printing.",
      numberOfCourses: "23 Courses",
      image: "logo-1.webp",
      link: "https://agiuae.com/",
      loading_type:"lazy",
      image_alt : "AGI Logo"
    },
    {
      title: "CIIE",
      info: "Lorem Ipsum is simply for this dummy text of the printing.",
      numberOfCourses: "18 Courses",
      image: "logo-2.webp",
      link: "https://www.centralinstitute.ae/",
      loading_type:"lazy",
      image_alt : "CIIE Logo"
    },
    {
      title: "AMS",
      info: "Lorem Ipsum is simply for this dummy text of the printing.",
      numberOfCourses: "15 Courses",
      image: "logo-3.webp",
      link: "https://academyofmulti-skills.org.uk/",
      loading_type:"lazy",
      image_alt : "AMS Logo"
    },
    {
        title: "LAAT",
        info: "Lorem Ipsum is simply for this dummy text of the printing.",
        numberOfCourses: "20 Courses",
        image: "logo-4.webp",
        link: "https://laatedu.com/",
        loading_type:"lazy",
        image_alt : "LAAT Logo"
      },
    {
      title: "ESL",
      info: "Lorem Ipsum is simply for this dummy text of the printing.",
      numberOfCourses: "20 Courses",
      image: "logo-5.webp",
      link: "https://www.elizabethschool.com/",
      loading_type:"lazy",
      image_alt : "ESL Logo"
    },
    {
      title: "TWI",
      info: "Lorem Ipsum is simply for this dummy text of the printing.",
      numberOfCourses: "20 Courses",
      image: "logo-6.webp",
      link: "https://laatedu.com/",
      loading_type:"lazy",
      image_alt : "TWI Logo"
    },
    {
      title: "UKCBC",
      info: "Lorem Ipsum is simply for this dummy text of the printing.",
      numberOfCourses: "20 Courses",
      image: "logo-7.webp",
      link: "https://laatedu.com/",
      loading_type:"lazy",
      image_alt : "UKCBC Logo"
    },
  ];
const HomeCategory = () => {
    return (

        <div className="home-one-cat edu-service-area service-wrapper-1 edu-section-gap bg-image">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <SectionTitle
                            classes="text-center"
                            slogan="Explore the Brain"
                            title="Vocational Qualifications"
                        />
                    </div>
                </div>

                <Slider
                    className="eduvibe-post-one-carousel-wrapper mt--40 mb--50 edu-slick-button"
                    {...BlogCarouselParams}
                >
                    {
                        items.map((item) => (
                            <div className="eduvibe-post-one-carousel-item" key={item}>
                                <Category data={item} />
                            </div>
                        ))
                    }
                </Slider>
                {/* <Category /> */}

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-2">
                        <img loading='lazy' src="/images/shapes/shape-08.webp" alt="Shape Thumb" width="205" height="118"/>
                    </div>
                    <div className="shape-image shape-image-3">
                        <img loading='lazy' src="/images/shapes/shape-04-01.webp" alt="Shape Thumb" width="87" height="116"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeCategory;