import React, { useContext, useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import guests_reducer from '../reducers/guest_reducer';

const defaultState = {
    guests_loading: false,
    guests_error: false,
    guests: [],
    pagination:[],
    filter_guests: []
}

const GuestsContext = React.createContext();

export const GuestsProvider = ( { children } ) => {
    const [state, dispatch] = useReducer( guests_reducer, defaultState );
    const defaultFilters = {
      'Job Type': [
        { name: 'Banker', selected: false },
        { name: 'Business Personnel', selected: false },
        { name: 'Designer', selected: false },
        { name: 'E-commerce', selected: false },
        { name: 'Education', selected: false },
        { name: 'Engineer', selected: false },
        { name: 'Engineering and Construction', selected: false },
        { name: 'Entertainment and Media', selected: false },
        { name: 'Environmental Conservation', selected: false },
        { name: 'Finance', selected: false },
        { name: 'Freelancer', selected: false },
        { name: 'Hospitality and Tourism', selected: false },
        { name: 'Sustainability & Renewable Energy', selected: false },
        { name: 'Technology', selected: false },
        { name: 'Other', selected: false },
      ],
      Mode: [
        { name: 'Face-To-Face', selected: false },
        { name: 'Online', selected: false },
      ],
      Availability: [
        { name: 'Sunday', selected: false },
        { name: 'Monday', selected: false },
        { name: 'Tuesday', selected: false },
        { name: 'Wednesday', selected: false },
        { name: 'Thursday', selected: false },
        { name: 'Friday', selected: false },
        { name: 'Saturday', selected: false },
      ],
      'Time Slot': [
        { name: '9AM-12PM', selected: false },
        { name: '12PM-3PM', selected: false },
        { name: '3PM-6PM', selected: false },
        { name: '6PM-9PM', selected: false },
      ],
      Emirates: [
        { name: 'Abu Dhabi', selected: false },
        { name: 'Dubai', selected: false },
        { name: 'Sharjah', selected: false },
        { name: 'Umm Al Quwain', selected: false },
        { name: 'Ajman', selected: false },
        { name: 'Ras al Khaimah', selected: false },
        { name: 'Fujairah', selected: false },
        { name: 'London', selected: false },
      ],
    };
    const [filters, setFilters] = useState(defaultFilters);
    const [page, setPage] = useState(1); // Initialize state
    const baseUrl = "https://certificatesglobal.com/backend/public/api"
    const [search, setSearch] = useState('')
    const [filterString, setFilterString] = useState('');
    const [showForm, setShowForm] = useState(false)
//  console.log(showForm)

    const applyFilter = async (filters = {},) => {

      const { search, filters: filter } = filters
      let job_type = filter['Job Type'].map((f) => f.selected && f.name).filter(f => f).join(',')
      let mode = filter['Mode'].map((f) => f.selected && f.name).filter(f => f).join(',')
      let availability = filter['Availability'].map((f) => f.selected && f.name).filter(f => f).join(',')
      let time_slot = filter['Time Slot'].map((f) => f.selected && f.name).filter(f => f).join(',')
      let emirates = filter['Emirates'].map((f) => f.selected && f.name).filter(f => f).join(',')
  
      let filterString = `search=${search}&job_type=${job_type}&mode=${mode}&availability=${availability}&time_slot=${time_slot}&emirates=${emirates}`
        // console.log(filterString)
        setFilterString(filterString)
        fetchGuests(filterString)
      }

        const filterHandler = ({ type, name }) => {
        const updatedFilterByName = filters[type].map((filter) => ({
          ...filter,
          selected: filter.name === name ? !filter.selected : filter.selected,
        }))
        const updatedFilters = {
          ...filters,
          [type]: updatedFilterByName,
        }
        setFilters(updatedFilters)
        applyFilter({ search, filters: updatedFilters })
        setPage(1)
      }

      const fetchGuests = async (filters = '') => {
        dispatch({ type: 'GET_GUESTS_BEGIN' });
        try {
            const filterString = filters ? '&' + filters : '';
            const { data } = await axios.get(`${baseUrl}/teachers?page=${page}${filterString}`);
            if (!data.success) {
                throw new Error('Failed to fetch data');
            }
    
            dispatch({ type: 'GET_GUESTS_SUCCESS', payload: { guestsData: data.data.collection, pagination: data.data.pagination } });
        } catch (error) {
            console.error('Error fetching data:', error);
            dispatch({ type: 'GET_GUESTS_ERROR' });
        }
    };

    const resetFilters = () => {
      setFilters(defaultFilters);
      fetchGuests()
    };
    
    useEffect( () => {
        fetchGuests(filterString)
    }, [page,filterString] );

    const searchHandler = (e) => {
      if (e.target.value.match(/^[a-zA-Z0-9]*$/)) {
        setSearch(e.target.value)
        setPage(1)
        applyFilter({ search: e.target.value, filters }, 1)
      }
    }

    return (
        <GuestsContext.Provider value={ { ...state, fetchGuests,page,setPage,filters,filterHandler,setFilters,searchHandler,resetFilters,showForm,setShowForm } }>
            { children }
        </GuestsContext.Provider>
    )
}

export const useGuestsContext = () => {
    return useContext( GuestsContext )
}