import React from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

const NavItems = () => {
  return (
    <ul className="mainmenu">
      <li><NavLink to="/">Home</NavLink></li>
      <li><NavLink to="/about">About</NavLink></li>
      <li><NavLink to="/sed-news">SED News</NavLink></li>
      <li><NavLink to="/htbd">HTBD</NavLink></li>
      <li><NavLink to="/guest-lecture">Guest Lecture</NavLink></li>
      <li><NavLink to="/job-portal">Job Portal</NavLink></li>
      {/* <li><NavLink to="#">Contact Us</NavLink></li> */}
    </ul>
  );
};
export default NavItems;
