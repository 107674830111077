import React from 'react';

const BannerSEDNews = ( props ) => {
    return (
        <div className={`edu-blog-widget-2 widget-banner ${ props.extraClass || '' }`} >
            <div className="inner">
                <div className="content">
                    <div className="thumbnail">
                        <img className="w-100" src="/images/widget/banner/student-1.webp" alt="Banner Images" style={{borderRadius:"5px",}}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerSEDNews;