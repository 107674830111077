import React from "react";
import {useNewsContext } from "../../context/news_context";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import Breadcrumb from "../../common/breadcrumb/Breadcrumb";
import PostStandard from "../../components/sed-news/PostStandard";
import LatestPost from "../../components/sed-news/LatestPost";
import AboutSeedingBrains from "../../components/sed-news/AboutSeedingBrains";

const SEDNews = () => {
  const { filtered_news, page, setPage, pagination } = useNewsContext();

  const pageItems = Array.from(
    { length: pagination.last_page },
    (_, index) => index + 1
  );

  const data = filtered_news;

  const handlePage = async (pageNumber) => {
    try {
      if (page !== pageNumber) {
        setPage(pageNumber);
      }
    } catch (error) {
      console.log("An error occured please try after some time", error);
    }
    // setPage(pageNumber);
  };

  const nextPage = () => {
    if (pagination.last_page !== page) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page !== 1) {
      setPage(page - 1);
    }
  };

  return (
    <>
      <SEO title="News" />
      <Layout>
        <Breadcrumb
          title="SED News"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="SED News"
        />
        <div className="edu-elements-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-8 eduvibe-blog-standard-wrapper">
                {data !== undefined &&
                  data.map((item) => (
                    <div
                      className="edu-blog blog-type-1 radius-small eduvibe-post-standard mt--40"
                      key={item.id}
                    >
                      <PostStandard data={item} />
                    </div>
                  ))}
                {/* <div className="row">
                                    <div className="col-lg-12 mt--60">
                                        <PaginationOne news={PostData} />
                                        
                                    </div>
                                </div> */}
                {pagination.total > 1 && (
                  <div className="row">
                    <div className="col-lg-12 mt--60">
                      <div className="nav">
                        <ul className="edu-pagination">
                          <li className="prev-btn">
                            <button onClick={prevPage}>
                              <i className="ri-arrow-drop-left-line"></i>
                            </button>
                          </li>

                          {pageItems.map((pageNumber) => (
                            <li
                              key={pageNumber}
                              className={`page-item ${pagination.current_page === pageNumber ? "active" : ""
                                }`}
                            >
                              <button onClick={() => handlePage(pageNumber)}>
                                {pageNumber}
                              </button>
                            </li>
                          ))}

                          <li className="next-btn">
                            <button onClick={nextPage}>
                              <i className="ri-arrow-drop-right-line"></i>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-lg-4">
                <aside className="edu-blog-sidebar">
                  {/* <SearchOne /> */}
                  {/* <CategoryOne extraClass="mt--40" /> */}
                  <LatestPost extraClass="mt--1" style2="enable" />
                  <AboutSeedingBrains extraClass="mt--40" />
                  {/* <TagOne extraClass="mt--40" /> */}
                  {/* <InstagramOne extraClass="mt--40" /> */}
                </aside>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default SEDNews;
