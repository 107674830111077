import React, { useState, useContext } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Accordion } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import SectionTitle from '../sectionTitle/SectionTitle';
import { Link } from 'react-router-dom';

const accordionItems = [
    {
        id: 1,
        title: 'What does it take become an author?',
        body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress LMS plugin you go with, but in general.'
    },
    {
        id: 2,
        title: 'How to Change my Password easily?',
        body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress LMS plugin you go with, but in general.'
    },
    {
        id: 3,
        title: 'How to Change my Plan using PayPal?',
        body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress LMS plugin you go with, but in general.'
    },
    {
        id: 4,
        title: 'How long it take to create a video course?',
        body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress LMS plugin you go with, but in general.'
    }
]

const CustomToggle = ({ children, eventKey }) => {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton( eventKey );
    const isCurrentEventKey = activeEventKey === eventKey;
    return <button type="button" onClick={decoratedOnClick} aria-expanded={ isCurrentEventKey ? true : false } className="edu-accordion-button">{children}</button>
}

const HomeHTBD = () => {
    const [activeId, setActiveId] = useState( '0' );
    function toggleActive( id ) {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }

    return (
        <div className="edu-accordion-area eduvibe-home-three-accordion accordion-shape-1 edu-section-gapBottom bg-color-white">
            <div className="container eduvibe-animated-shape">
                <div className="row g-5">
                    <div className="col-lg-6 gallery-wrapper">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="thumbnail">
                                    <img className="radius-small w-100" src="/images/home/about-htbd/htbd-home.webp" alt="About Thumb 1" width="250" height="530" loading='lazy'/>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 mt--35 mt_sm--15">
                                <div className="thumbnail">
                                    <Link to="/htbd">
                                    <img className="radius-small w-100" src="/images/home/about-htbd/htbd-logo.webp" alt="About Thumb 2" width="258" height="263" loading='lazy'/>
                                    </Link>
                                </div>
                                <div className="thumbnail mt--15">
                                    <Link to="#">
                                    <img className="radius-small w-100" src="/images/home/about-htbd/crictics-logo.webp" alt="About Thumb 3" width="250" height="255" loading='lazy'/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-lg-6">
                        <SectionTitle
                            classes = "text-start mb--40"
                            slogan = "About Us"
                            title = "Get Every General Answers From Here"
                        />
                        <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            className="accordion-style-1"
                            animateOnce={ true }
                        >
                            <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                {
                                    accordionItems.map( ( accordion, i ) => (
                                        <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive(i.toString())} bsPrefix={`edu-accordion-item ${activeId === i.toString() ? 'active-item' : ''}`}>
                                            <div className="edu-accordion-header">
                                                <CustomToggle eventKey={ i.toString() }>{ accordion.title }</CustomToggle>
                                            </div>
                                            <Accordion.Body bsPrefix="edu-accordion-body">{ accordion.body }</Accordion.Body>
                                        </Accordion.Item>
                                    ) )
                                }
                            </Accordion>
                        </ScrollAnimation>
                    </div> */}
                                        <div className="col-lg-12 col-xl-6">
                        <div className="inner mt_mobile--40">
                            <div className="section-title text-start">
                                <span className="pre-title">HTBD</span>
                                <h3 className="title">We Offer The Best Carrier</h3>
                            </div>
                            <div className="feature-style-6 mt--40">
                                <div className="edu-feature-list color-variation-1">
                                    <div className="icon">
                                        <img src="/images/about/about-05/student.webp" alt="Icons Images" width="30" height="30" loading='lazy'/>
                                    </div>
                                    <div className="content">
                                        {/* <h6 className="title">Cultural Intelligence and Business Etiquette</h6> */}
                                        <h3 className="title fs-2">Cultural Intelligence and Business Etiquette</h3>
                                        <p className="description">Learn the essential cultural nuances and business etiquette specific to Dubai to build strong, trust-based relationships with local partners and clients.</p>
                                    </div>
                                </div>

                                <div className="edu-feature-list color-variation-2">
                                    <div className="icon">
                                        <img src="/images/about/about-05/book.webp" alt="Icons Images" width="30" height="30" loading='lazy'/>
                                    </div>
                                    <div className="content">
                                        <h3 className="title fs-2">Legal and Regulatory Framework</h3>
                                        <p className="description">Navigate the legal and regulatory framework of Dubai, covering company formation, licensing, labor laws, taxation, and intellectual property rights to minimize risks and ensure compliance.</p>
                                    </div>
                                </div>
                                
                                {/* <div className="edu-feature-list color-variation-3">
                                    <div className="icon">
                                        <img src="/images/about/about-05/reward.webp" alt="Icons Images" />
                                    </div>
                                    <div className="content">
                                        <h6 className="title">Biggest Student Community</h6>
                                        <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmoded tempor incididunt dolore magna aliqua.</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="read-more-btn mt--30 ml--40">
                            <Link className="edu-btn" to="/htbd">Discover More<i className="icon-arrow-right-line-right"></i></Link>
                        </div>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape shape-1">
                        <span className="shape-dot"></span>
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-11-04.webp" alt="Shape Thumb" width="85" height="77" loading='lazy'/>
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-25-01.webp" alt="Shape Thumb" width="91" height="101" loading='lazy'/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeHTBD;