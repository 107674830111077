import React from 'react';
import { Element } from 'react-scroll';
import SectionTitle from '../sectionTitle/SectionTitle';
import CategoryThree from '../category/CategoryThree';

const PopularEvidence = () => {
    return (
        <Element
            name="service"
            className="sercice-area eduvibe-service-five service-wrapper-5 edu-section-gap bg-color-white"
        >
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <SectionTitle
                            classes = "text-center"
                            // slogan = "Popular Evidence"
                            title = "Popular Evidence"
                        />
                        <p className='description text-center mt--40'>Create an IBP to start a suitable business in Dubai, after gaining all the knowledge and skills in the course.</p>
                    </div>
                </div>

                <CategoryThree />

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-19-02.webp" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-21.webp" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </Element>
    )
}

export default PopularEvidence;