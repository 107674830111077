import React from 'react';
import Slider from 'react-slick';
import { TestimonialOneParams } from '../../utils/script';

let data = [
    {
        name: "Geraldine D. Anspach",
        details: "Engagement redefined by Seeding Brains! The immersive tech and personalized approach transformed my learning journey completely.",
        designation: "Student",
        image: "user.webp"
    },
    {
        name: "Lorraine D. Raines",
        details: "Seeding Brains breaks barriers, enabling seamless learning from home. Education becomes effortless and impactful, accessible to all.",
        designation: "Student",
        image: "user.webp"
    },
    {
        name: "David M. Bard",
        details: "Sparking collaboration, Seeding Brains ignites a collective pursuit of knowledge, redefining the essence of education through connecting, sharing, and growing together.",
        designation: "Student",
        image: "user.webp"
    },
    {
        name: "Julia H. Smith",
        details: "Seeding Brains sparks collaboration, igniting a collective pursuit of knowledge. Connecting, sharing, and growing together redefine the essence of education.",
        designation: "Student",
        image: "user.webp"
    },
];

const TestimonialOne = ( props ) => {
    const classes = `testimonial-activation testimonial-style-1 edu-slick-button slick-button-left ${props.className ? props.className : '' }`;
    data = props.item ? data.slice( 0, props.item ) : data;
    return (
        <Slider 
            className={ classes }
            { ...TestimonialOneParams }
        >
            { data.map( ( item, i ) => (
                <div className="single-testimonial" key={ i }>
                    <div className="inner">
                        { props.quoteIcon !== 'disable' && 
                            <div className="quote-sign">
                                <img src="/images/testimonial/quote.webp" alt="Quote Images" width="70" height="75" loading='lazy'/>
                            </div>
                        }
                        <p className="description">“ { item.details }”</p>
                        <div className="client-information">
                            <div className="thumb">
                                <img src={`${process.env.PUBLIC_URL}/images/testimonial/${item.image}`} alt="Client Thumb" width="80" height="80" loading='lazy'/>
                            </div>
                            <div className="info">
                                <div className="title title-text">{ item.name }</div>
                                <span className="designation">{ item.designation }</span>
                            </div>
                        </div>
                    </div>
                </div>
            ) ) }
        </Slider>
    );
}
export default TestimonialOne;