const jobs_reducer = ( state, action ) => {
    if ( action.type === 'GET_JOBS_BEGIN' ) {
        return { 
            ...state, 
            jobs_loading: true 
        }
    }

    if ( action.type === 'GET_JOBS_SUCCESS' ) {

        return {
            ...state,
            jobs_loading: false,
            jobs: action.payload.jobsData,
            pagination : action.payload.pagination,
            // page:action.payload.page,
            // filter_jobs:[...action.payload.jobsData]
        }
    }

    if ( action.type === 'GET_JOBS_ERROR' ) {
        return { 
            ...state, 
            jobs_loading: false, 
            jobs_error: true 
        }
    }

    if(action.type === 'FILTER_JOBS'){
        return{
            ...state,
            filter_jobs:action.payload.jobsData
        }
    }

    throw new Error( `No Matching '${action.type}' - action type found.` );
}
  
export default jobs_reducer;