import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const FormSuccess = () => {
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Body >
        <div className="modal-body text-center p-lg-4">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 130.2 130.2"
              style={{width: "100px",display: "block",margin: "0 auto" }}
            >
              <circle
                className="path circle"
                fill="none"
                stroke="#198754"
                strokeWidth={6}
                strokeMiterlimit={10}
                cx="65.1"
                cy="65.1"
                r="62.1"
              />
              <polyline
                className="path check"
                fill="none"
                stroke="#198754"
                strokeWidth={6}
                strokeLinecap="round"
                strokeMiterlimit={10}
                points="100.2,40.2 51.5,88.8 29.8,67.5 "
              />
            </svg>
            <h4 className="text-success mt-3">Thank You !</h4>
            <p className="mt-3">
              You have been successfully registered.
            </p>
            <button
          type="button"
          className="btn btn-lg mt-3 fs-3 btn-success"
          data-bs-dismiss="modal"
          onClick={handleClose}
        >
          Ok
        </button>
      </div>
          </Modal.Body>
         {/* <Modal.Footer>
           <Button variant="secondary" onClick={handleClose}>
             Close
           </Button>
         </Modal.Footer> */}
    </Modal>
  )
}

export default FormSuccess
