import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useGuestsContext } from "../../context/guests-context";
import InputForm from "./InputForm";
import SortGuest from "./SortGuest";
import GuestTypeThree from "./GuestTypeThree";
// import { useGuestFilterContext } from "../../context/guestsfilter_context";
// import GuestTypeThree from "./GuestTypeThree";
// import SortGuest from "./SortGuest";
// import { useGuestsContext } from "../../context/guests-context";
// import InputForm from "./InputForm";

const GuestItemsForFilter = () => {
  const { pagination, page, setPage,filtered_guests ,guests,showForm} = useGuestsContext();
  

  const pageItems = Array.from(
    { length: pagination.last_page },
    (_, index) => index + 1
  );

  const guestsPerPage = pagination.per_page;
  const data = guests;

  const handlePage = async (pageNumber) => {
    try {
      if (page !== pageNumber) {
        setPage(pageNumber);
        // await applyFilter({ filters }, pageNumber)
      }
    } catch (error) {
      console.log("An error occured please try after some time", error);
    }
    // setPage(pageNumber);
  };

  const nextPage = () => {
    if (pagination.last_page !== page) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page !== 1) {
      setPage(page - 1);
    }
  };

  return (
    <>
{
  showForm ? (<InputForm/>
):""
}
      <SortGuest showPerPage={guestsPerPage} />

      <div className="row g-5 mt--10">
        {data !== undefined &&
          data.map((guest) => {
            return (
              <div key={guest.id} className="col-lg-6 col-md-6">
                <GuestTypeThree data={guest} />
              </div>
            );
          })}
      </div>

      {pagination.total > 1 && (
        <div className="row">
          <div className="col-lg-12 mt--60">
            <div className="nav">
              <ul className="edu-pagination">
                <li className="prev-btn">
                  <button onClick={prevPage}>
                    <i className="ri-arrow-drop-left-line"></i>
                  </button>
                </li>

                {pageItems.map((pageNumber) => (
                  <li
                    key={pageNumber}
                    className={`page-item ${
                      pagination.current_page === pageNumber ? "active" : ""
                    }`}
                  >
                    <button onClick={() => handlePage(pageNumber)}>
                      {pageNumber}
                    </button>
                  </li>
                ))}

                <li className="next-btn">
                  <button onClick={nextPage}>
                    <i className="ri-arrow-drop-right-line"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}


    </>
  );
};

export default GuestItemsForFilter;
