import PropTypes from 'prop-types';
import React from 'react'


const SEO = ( { title,preload } ) => {
    return (
        <>
        {
            preload ? <link rel="preload" as="image" href={preload}/> : ""
        }
            <meta charSet="utf-8" />
            <title>{ title } || Seeding Brains | Explore the brain: Ignite curiosity, unlock potential, shape futures.</title>
            <meta name="robots" content="index, follow" />
            <meta name="description" content="Seeding Brains : Dive into 'Explore the Brain' — unraveling its complexities, igniting curiosity, and shaping futures through understanding the wonders of neuroscience." />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;