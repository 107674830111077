import React from 'react';
import FeatureOne from '../../components/feature/FeatureOne';
import SectionTitle from '../../components/sectionTitle/SectionTitle';

const AboutUsService = () => {
    return (
        <div className="eduvibe-about-us-one-service edu-service-area edu-section-gapBottom bg-color-white service-bg-position">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <SectionTitle
                            classes = "text-center"
                            slogan = "What We Offer"
                            title = "Vocational Qualifications and<br /> Re-Skilling Diploma"
                        />
                    </div>
                </div>

                <FeatureOne />

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-04-03.webp" alt="Shape Thumb" width="39" height="83" loading="lazy"/>
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-02-07.webp" alt="Shape Thumb" width="67" height="74" loading="lazy"/>
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-15.webp" alt="Shape Thumb" width="39" height="43" loading="lazy"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUsService;